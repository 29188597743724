import { IPosition } from "../models";

import SPY_QQQ_UVXY_img from "../assets/img/stocks/SPY-QQQ-UVXY.png";
import USO_img from "../assets/img/stocks/USO.png";
import AAPL_img from "../assets/img/stocks/AAPL.png";
import TSLA_img from "../assets/img/stocks/TSLA.png";
import AMZN_img from "../assets/img/stocks/AMZN.png";
import FB_img from "../assets/img/stocks/FB.png";
import AMD_img from "../assets/img/stocks/AMD.png";
import NVDA_img from "../assets/img/stocks/NVDA.png";

import HOOD_img from "../assets/img/stocks/HOOD.png";
import COIN_img from "../assets/img/stocks/COIN.png";

import GME_img from "../assets/img/stocks/GME.png";
import SOFI_img from "../assets/img/stocks/SOFI.png";
import AMC_img from "../assets/img/stocks/AMC.png";
import WISH_img from "../assets/img/stocks/WISH.png";

import ROKU_img from "../assets/img/stocks/ROKU.png";
import SNAP_img from "../assets/img/stocks/SNAP.png";
import SQ_img from "../assets/img/stocks/SQ.png";
import RIOT_img from "../assets/img/stocks/RIOT.png";

import BAC_img from "../assets/img/stocks/BAC.png";
import WMT_img from "../assets/img/stocks/WMT.png";
import AAL_img from "../assets/img/stocks/AAL.png";
import BABA_img from "../assets/img/stocks/BABA.png";

import NIO_img from "../assets/img/stocks/NIO.png";
import ABNB_img from "../assets/img/stocks/ABNB.png";
import GLD_img from "../assets/img/stocks/GLD.png";
import TSM_img from "../assets/img/stocks/TSM.png";

import BTC_img from "../assets/img/stocks/BTC.png";
import ETH_img from "../assets/img/stocks/ETH.png";
import SOL_img from "../assets/img/stocks/SOL.png";
import LUNA_img from "../assets/img/stocks/LUNA.png";
import AVAX_img from "../assets/img/stocks/AVAX.png";

import SBUX_img from "../assets/img/stocks/SBUX.png";
import PLTR_img from "../assets/img/stocks/PLTR.png";
import WEN_img from "../assets/img/stocks/WEN.png";
import CORN_img from "../assets/img/stocks/CORN.png"; //
import TWTR_img from "../assets/img/stocks/TWTR.png";
import NKE_img from "../assets/img/stocks/NKE.png";

import DIS_img from "../assets/img/stocks/DIS.png";

import MSFT_img from "../assets/img/stocks/MSFT.png";
import MU_img from "../assets/img/stocks/MU.png";

import CMG_img from "../assets/img/stocks/CMG.png";
import LULU_img from "../assets/img/stocks/LULU.png";
import COST_img from "../assets/img/stocks/COST.png";
import MCD_img from "../assets/img/stocks/MCD.png";

import NFLX_img from "../assets/img/stocks/NFLX.png";
import StockBet_img from "../assets/img/stocks/StockBet.png";
import { TypeOptionsHome } from "../context/AuthContext";
import { TypeTimeOptionsActive } from "../components/symbol/BuySellBox";

export interface IStocks {
  name: string;
  data: IStocksData[];
}

export interface IStocksData {
  stt?: number;
  name?: string;
  company?: string;
  avatar?: string;
  //
  question?: string;
  questionMobile?: string;
  closeDate?: string;
  expirationDate?: string;
  sourceAgency?: string;
  sourceAgencyLink?: string;
  underlying?: string;
  marketResolution?: string;
}

export interface IDataChartHistorical {
  symbol: string;
  unit?: string;
  unitFront?: string;
  maxBarSize?: number;
  maxBarSizeMobile?: number;
  min?: number;
  max?: number;
  // marginLeftText?: number;
  // marginLeftTextMobile?: number;
  data: Array<{ time: string; value: number; isFake?: boolean }>;
}

export const getNameSymbol = (symbol) => {
  return symbol == "BTCUSD"
    ? "BTC"
    : symbol == "ETHUSD"
    ? "ETH"
    : symbol == "FB"
    ? "META"
    : symbol;
};

export const getNameSymbolApi = (symbol) => {
  return symbol == "META" ? "FB" : symbol;
};

export const majorIndicesStocks: IStocks = {
  name: "Major Indices",
  data: [
    { name: "SPY", company: "S&P 500", avatar: SPY_QQQ_UVXY_img.src },
    { name: "QQQ", company: "NASDQ 100", avatar: SPY_QQQ_UVXY_img.src },
    { name: "UVXY", company: "VIX ETF", avatar: SPY_QQQ_UVXY_img.src },
    { name: "USO", company: "Oil ETF", avatar: USO_img.src },
  ],
};
export const techGiantsStocks: IStocks = {
  name: "Tech Giants",
  data: [
    { name: "AAPL", company: "Apple", avatar: AAPL_img.src },
    { name: "TSLA", company: "Tesla", avatar: TSLA_img.src },
    { name: "AMZN", company: "Amazon", avatar: AMZN_img.src },
    { name: "META", company: "Meta", avatar: FB_img.src },
    { name: "FB", company: "Meta", avatar: FB_img.src },
  ],
}; // "AAPL", "TSLA", "AMZN", "FB"

export const techStocks: IStocks = {
  name: "Tech Stocks",
  data: [
    { name: "AMD", company: "AMD", avatar: AMD_img.src },
    { name: "NVDA", company: "Nvidia", avatar: NVDA_img.src },
    { name: "HOOD", company: "Robinhood", avatar: HOOD_img.src },
    { name: "COIN", company: "Coinbase", avatar: COIN_img.src },
  ],
};
//"AMD", "NVDA", "HOOD", "COIN"
export const memeStocks: IStocks = {
  name: "Meme Stocks",
  data: [
    { name: "GME", company: "GameStop", avatar: GME_img.src },
    { name: "AMC", company: "AMC Theatres", avatar: AMC_img.src },
    { name: "SOFI", company: "SoFi", avatar: SOFI_img.src },
    { name: "WISH", company: "ContextLogic", avatar: WISH_img.src },
  ],
};

export const fanVotesStocks: IStocks = {
  name: "Fan Votes",
  data: [
    { name: "NIO", company: "NIO", avatar: NIO_img.src },
    { name: "ABNB", company: "Airbnb", avatar: ABNB_img.src },
    // { name: "GLD", company: "SPDR Gold", avatar: GLD_img.src },
    { name: "TWTR", company: "Twitter", avatar: TWTR_img.src },
    { name: "TSM", company: "TSMC", avatar: TSM_img.src },
  ],
};

// "GME", "AMC", "SOFI", "WISH"
export const growthStocks: IStocks = {
  name: "Growth Stocks",
  data: [
    { name: "ROKU", company: "Roku", avatar: ROKU_img.src },
    { name: "SNAP", company: "Snapchat", avatar: SNAP_img.src },
    { name: "SQ", company: "Block", avatar: SQ_img.src },
    { name: "RIOT", company: "Riot Blockchain", avatar: RIOT_img.src },
  ],
};
//"ROKU", "SNAP", "SQ", "RIOT"

export const otherStocks: IStocks = {
  name: "Other Stocks",
  data: [
    { name: "BAC", company: "Bank of America", avatar: BAC_img.src },
    { name: "WMT", company: "Walmart", avatar: WMT_img.src },
    { name: "AAL", company: "American Airlines", avatar: AAL_img.src },
    { name: "BABA", company: "Alibaba", avatar: BABA_img.src },
  ],
};

export const consumerStocks: IStocks = {
  name: "Consumer Stocks",
  data: [
    { name: "COST", company: "Costco", avatar: COST_img.src },
    { name: "CMG", company: "Chipotle", avatar: CMG_img.src },
    { name: "LULU", company: "Lululemon", avatar: LULU_img.src },
    { name: "MCD", company: "McDonalds", avatar: MCD_img.src },
  ],
};

export const sectorsStocks: IStocks = {
  name: "sectorsStocks",
  data: [
    { name: "XLF", company: "XLF", avatar: SPY_QQQ_UVXY_img.src },
    { name: "XLK", company: "XLK", avatar: SPY_QQQ_UVXY_img.src },
    { name: "XLE", company: "XLE", avatar: SPY_QQQ_UVXY_img.src },
    { name: "XLP", company: "XLP", avatar: SPY_QQQ_UVXY_img.src },
  ],
};

export const votesNewStocks: IStocks = {
  name: "Vote New Stocks",
  data: [
    { name: "SBUX", company: "Starbucks", avatar: SBUX_img.src },
    { name: "PLTR", company: "Palantir", avatar: PLTR_img.src },
    { name: "WEN", company: "Wendys", avatar: WEN_img.src },
    // { name: "CORN", company: "Corn Fund", avatar: CORN_img.src },TWTR
    // { name: "TWTR", company: "Twitter", avatar: TWTR_img.src },
    { name: "NKE", company: "Nike", avatar: NKE_img.src },

    { name: "DIS", company: "Disney", avatar: DIS_img.src },
    { name: "CMG", company: "Chipotle", avatar: CMG_img.src },
    { name: "MCD", company: "McDonalds", avatar: MCD_img.src },
    { name: "MSFT", company: " Microsoft", avatar: MSFT_img.src },
    { name: "MU", company: "Micron", avatar: MU_img.src },
  ],
};

export const cryptoStocks: IStocks = {
  name: "Cryptocurrencies",
  data: [
    { name: "BTC", company: "Bitcoin", avatar: BTC_img.src },
    { name: "ETH", company: "Ethereum", avatar: ETH_img.src },
    { name: "SOL", company: "Solana", avatar: SOL_img.src },
    { name: "AVAX", company: "Avalanche", avatar: AVAX_img.src },
  ],
};

export const metricsSymbols: IStocks = {
  name: "Metrics Markets",
  data: [
    // {
    //   stt: 1,
    //   name: "NFLX",
    //   company: "Netflix Inc",
    //   avatar: NFLX_img.src,
    //   question: "Will NFLX lose global paid subscribers in Q2 2022?",
    //   questionMobile: "Will NFLX lose paid subscribers in Q2 2022?",
    //   closeDate: "2022-07-16" || "Jul 16, 2022 11:59PM",
    //   expirationDate: "2022-07-18" || "Jul 18, 2022 4:00PM",
    //   sourceAgency: "Netflix Q2 2022 Shareholder Letter",
    //   sourceAgencyLink:
    //     "https://ir.netflix.net/financials/quarterly-earnings/default.aspx",
    //   underlying:
    //     "The number of global paid subscribers as reported by Netflix’s Q2 earnings report." ||
    //     "The number of nights and experiences booked as reported by Airbnb’s Q2 2022 earnings report." ||
    //     "The number of global paid subscribers as reported by Netflix’s Q2 2022 earnings report.",
    //   marketResolution:
    //     "If NFLX reports a net loss of global paid subscribers in its Q2 2022 earnings report, then the market resolves to Yes. Else, the market resolves to No.",
    // },
    // {
    //   stt: 2,
    //   name: "SBET",
    //   company: "StockBet",
    //   avatar: StockBet_img.src,
    //   question: "Will StockBet reach $10,000 volume in May 2022?",
    //   closeDate: "2022-05-31" || "May 31, 2022 11:59PM",
    //   expirationDate: "2022-06-01" || "June 1, 2022 4:00PM",
    //   sourceAgency: "StockBet May 2022 Investor Update",
    //   sourceAgencyLink:
    //     "https://stockbet.notion.site/StockBet-Metrics-alpha-v-0-4-1-8945579e4aed415dba9fc0cc1227a67f",
    //   underlying:
    //     "The amount of trading volume as reported by StockBet’s May 2022 investor update." ||
    //     "The number of global paid subscribers as reported by StockBet’s May 2022 investor update.",
    //   marketResolution:
    //     "If StockBet reports more than $10,000 volume in its May investor update, then the market resolves to Yes. Else, the market resolves to No.",
    // },
    // {
    //   stt: 3,
    //   name: "HOOD",
    //   company: "Robinhood",
    //   avatar: HOOD_img.src,
    //   question: "Will HOOD lose monthly active users in Q2 2022?",
    //   closeDate: "2022-08-17" || "Aug 17, 2022 11:59PM",
    //   expirationDate: "2022-08-19" || "Aug 19, 2022 4:00PM",
    //   sourceAgency: "Robinhood Q2 2022 Shareholder Letter",
    //   sourceAgencyLink: "https://investors.robinhood.com/overview/default.aspx",
    //   underlying:
    //     "The number of monthly active users as reported by Robinhood’s Q2 2022 earnings report." ||
    //     "The number of global paid subscribers as reported by Robinhood’s Q2 2022 Earnings Report.",
    //   marketResolution:
    //     "If HOOD reports a net loss of monthly active users in its Q2 2022 earnings report, then the market resolves to Yes. Else, the market resolves to No.",
    // },
    // {
    //   stt: 4,
    //   name: "ABNB",
    //   company: "Airbnb",
    //   avatar: ABNB_img.src,
    //   question: "Will ABNB report >110M nights booked in Q2 2022?",
    //   closeDate: "2022-08-10" || "Aug 10, 2022 11:59PM",
    //   expirationDate: "2022-08-12" || "Aug 12, 2022 4:00PM",
    //   sourceAgency: "Airbnb Q2 2022 Shareholder Letter",
    //   sourceAgencyLink:
    //     "https://investors.airbnb.com/financials/default.aspx#quarterly",
    //   underlying:
    //     "The number of nights and experiences booked as reported by Airbnb’s Q2 2022 earnings report." ||
    //     "The number of global paid subscribers as reported by Airbnb’s Q2 earnings report.",
    //   marketResolution:
    //     "If ABNB reports more than 110M nights and experiences booked in its Q2 2022 earnings report, then the market resolves to Yes. Else, the market resolves to No.",
    // },
    {
      stt: 5,
      name: "NFLX",
      company: "Netflix Inc",
      avatar: NFLX_img.src,
      question: "Will NFLX lose global paid subscribers in Q3 2022?",
      questionMobile: "Will NFLX lose paid subscribers in Q3 2022?",
      closeDate: "2022-10-26" || "Jul 16, 2022 11:59PM",
      expirationDate: "2022-10-28" || "Jul 18, 2022 4:00PM",
      sourceAgency: "Netflix Q3 2022 Shareholder Letter",
      sourceAgencyLink:
        "https://ir.netflix.net/financials/quarterly-earnings/default.aspx",
      underlying:
        "The number of global paid subscribers as reported by Netflix’s Q3 earnings report." ||
        "The number of nights and experiences booked as reported by Airbnb’s Q3 2022 earnings report." ||
        "The number of global paid subscribers as reported by Netflix’s Q3 2022 earnings report.",
      marketResolution:
        "If NFLX reports a net loss of global paid subscribers in its Q3 2022 earnings report, then the market resolves to Yes. Else, the market resolves to No.",
    },
    {
      stt: 6,
      name: "HOOD",
      company: "Robinhood",
      avatar: HOOD_img.src,
      question: "Will HOOD lose monthly active users in Q3 2022?",
      closeDate: "2022-10-26" || "Aug 17, 2022 11:59PM",
      expirationDate: "2022-10-28" || "Aug 19, 2022 4:00PM",
      sourceAgency: "Robinhood Q2 2022 Shareholder Letter",
      sourceAgencyLink: "https://investors.robinhood.com/overview/default.aspx",
      underlying:
        "The number of monthly active users as reported by Robinhood’s Q3 2022 earnings report." ||
        "The number of global paid subscribers as reported by Robinhood’s Q3 2022 Earnings Report.",
      marketResolution:
        "If HOOD reports a net loss of monthly active users in its Q3 2022 earnings report, then the market resolves to Yes. Else, the market resolves to No.",
    },
    {
      stt: 7,
      name: "ABNB",
      company: "Airbnb",
      avatar: ABNB_img.src,
      question: "Will ABNB report >110M nights booked in Q3 2022?",
      closeDate: "2022-10-26" || "Aug 10, 2022 11:59PM",
      expirationDate: "2022-10-28" || "Aug 12, 2022 4:00PM",
      sourceAgency: "Airbnb Q3 2022 Shareholder Letter",
      sourceAgencyLink:
        "https://investors.airbnb.com/financials/default.aspx#quarterly",
      underlying:
        "The number of nights and experiences booked as reported by Airbnb’s Q3 2022 earnings report." ||
        "The number of global paid subscribers as reported by Airbnb’s Q3 earnings report.",
      marketResolution:
        "If ABNB reports more than 110M nights and experiences booked in its Q3 2022 earnings report, then the market resolves to Yes. Else, the market resolves to No.",
    },
  ],
};

export const dataChartHistorical: IDataChartHistorical[] = [
  {
    symbol: "NFLX",
    unit: "M",
    maxBarSize: 55,
    maxBarSizeMobile: 30,
    min: 150 || 0, // 200,
    max: 150 + (221.85 - 150) / 0.8 || 250, // Set tallest bar as 80% of max height
    // marginLeftText: 5,
    // marginLeftTextMobile: 0,
    data: [
      {
        time: "Q4 2020",
        value: 203.66,
      },
      {
        time: "Q1 2021",
        value: 207.63,
      },
      {
        time: "Q2 2021",
        value: 209.19,
      },
      {
        time: "Q3 2021",
        value: 213.56,
      },
      {
        time: "Q4 2021",
        value: 221.85,
      },
      {
        time: "Q1 2022",
        value: 221.64,
      },
      {
        time: "Q2 2022",
        value: 220.67,
      },
      {
        time: "Q3 2022",
        value: 221.64 || 235, // fake
        isFake: true,
      },
    ],
  },
  {
    symbol: "SBET",
    unitFront: "$",
    maxBarSize: 50,
    maxBarSizeMobile: 25,
    min: 0, // 500,
    max: 2252 / 0.8 || 2600,
    // marginLeftText: 7,
    // marginLeftTextMobile: 0,
    data: [
      {
        time: "Mar 6",
        value: 1113,
      },
      {
        time: "Mar 13",
        value: 1183,
      },
      {
        time: "Mar 20",
        value: 1506,
      },
      {
        time: "Mar 27",
        value: 777,
      },
      {
        time: "Apr 3",
        value: 613,
      },
      {
        time: "Apr 10",
        value: 626,
      },
      {
        time: "Apr 17",
        value: 1458,
      },
      {
        time: "Apr 24",
        value: 2252,
      },
      {
        time: "May 1",
        value: 2252 || 2350,
        isFake: true,
      },
    ],
  },
  {
    symbol: "HOOD",
    unit: "M",
    maxBarSize: 55,
    maxBarSizeMobile: 30,
    min: 0, // 10,
    max: 21.3 / 0.8 || 25,
    // marginLeftText: 12,
    // marginLeftTextMobile: 0,
    data: [
      {
        time: "Q4 2020",
        value: 12.5,
      },
      {
        time: "Q1 2021",
        value: 17.7,
      },
      {
        time: "Q2 2021",
        value: 21.3,
      },
      {
        time: "Q3 2021",
        value: 18.9,
      },
      {
        time: "Q4 2021",
        value: 17.3,
      },
      {
        time: "Q1 2022",
        value: 15.9,
      },
      {
        time: "Q2 2022",
        value: 14,
      },
      {
        time: "Q3 2022",
        value: 15.9 || 17,
        isFake: true,
      },
    ],
  },
  {
    symbol: "ABNB",
    unit: "M",
    maxBarSize: 55,
    maxBarSizeMobile: 30,
    min: 0, // 40,
    max: 102.1 / 0.8 || 115,
    // marginLeftText: 12,
    // marginLeftTextMobile: 0,
    data: [
      {
        time: "Q4 2020",
        value: 46.3,
      },
      {
        time: "Q1 2021",
        value: 64.4,
      },
      {
        time: "Q2 2021",
        value: 83.1,
      },
      {
        time: "Q3 2021",
        value: 79.7,
      },
      {
        time: "Q4 2021",
        value: 73.4,
      },
      {
        time: "Q1 2022",
        value: 102.1,
      },

      {
        time: "Q2 2022",
        value: 103.7,
      },
      {
        time: "Q3 2022",
        value: 102.1 || 105,
        isFake: true,
      },
    ],
  },
];

export const allStockData = [
  ...majorIndicesStocks.data,
  ...techGiantsStocks.data,
  ...cryptoStocks.data,
  ...techStocks.data,
  ...memeStocks.data,
  ...growthStocks.data,
  ...otherStocks.data,
  ...fanVotesStocks.data,
  ...metricsSymbols.data,
  ...consumerStocks.data,
  ...sectorsStocks.data,
];

export const getInfoStock = (symbol): IStocksData | undefined => {
  return allStockData.find((item) => item.name == symbol);
};

//"BAC", "WMT", "AAL", "BABA"

export const selectedData = (allData: IStocks[], stock: string) => {
  let result;
  for (let i = 0; i < allData.length; i++) {
    if (allData[i].data.map((item) => item.name).includes(stock)) {
      result = allData[i];
      break;
    }
  }

  const indexOfStock = result?.data?.map((item) => item.name).indexOf(stock);

  if (indexOfStock > -1) {
    const newData = [...result?.data];
    newData.splice(indexOfStock, 1); // 2nd parameter means remove one item only
    result = {
      ...result,
      data: newData,
    };
  }
  return result;
};

export const calcChangeAvgPrice = (avgPrice: any, currAvgPrice: any) => {
  if (!avgPrice || !currAvgPrice) return "";
  const result = ((currAvgPrice - avgPrice) / currAvgPrice) * 100;
  if (result >= 0) return `+${result.toFixed(2)}%`;
  else return `${result.toFixed(2)}%`;
};

export const handlePosition = (data) => {
  let position: Array<IPosition> = [];
  data?.map((item) => {
    if (item?.GREEN?.totalShares > 0) {
      const _data: IPosition = {
        timeOption: item.timeOption as TypeTimeOptionsActive,
        today: item.today as boolean,
        name: item.name as string,
        share: item.GREEN.totalShares as number,
        avgPrice: item.GREEN.avgPrice as number,
        maxReturn: item.GREEN.maxReturn as number,
        price: item.GREEN.avgPrice as number,
        // ||
        // item.detailStock.greenTotal /
        //   (item.detailStock.greenTotal + item.detailStock.redTotal),
        // change: handleChange(
        //   item.detailStock.greenTotal,
        //   item.detailStock.redTotal,
        //   item.GREEN.avgPrice
        // ),
        green: true as boolean,
        totalValue: item.GREEN.totalValue as number,
        costBasis: item.GREEN.costBasis as number,
        entry: item.GREEN.entry as number,
      };
      position.push(_data);
    }
    if (item?.RED?.totalShares > 0) {
      const _data: IPosition = {
        timeOption: item.timeOption,
        today: item.today,
        name: item.name,
        share: item.RED.totalShares,
        avgPrice: item.RED.avgPrice,
        maxReturn: item.RED.maxReturn,
        price: item.RED.avgPrice,
        // ||
        // item.detailStock.redTotal /
        //   (item.detailStock.greenTotal + item.detailStock.redTotal),
        // change: handleChange(
        //   item.detailStock.redTotal,
        //   item.detailStock.greenTotal,
        //   item.RED.avgPrice
        // ),
        green: false,
        totalValue: item.RED.totalValue,
        costBasis: item.RED.costBasis,
        entry: item.RED.entry,
      };
      position.push(_data);
    }
    // update need display order limit
    if (!item?.RED && !item?.GREEN) {
      const _data: IPosition = {
        timeOption: item.timeOption,
        today: item.today,
        name: item.name,
        status: item.status,
        type: item.type,
        price: item.price,
        //
        limitPrice: item.limitPrice,
        filledShares: item.filledShares,
        remainingShares: item.remainingShares,
        totalShares: item.totalShares,
        green: item.type == "GREEN" ? true : false,
        dateBet: item.dateBet,
      };

      // console.log({item});
      // console.log({_data});
      position.push(_data);
    }
  });
  return position;
};
const handleChange = (t1, t2, avgPrice) => {
  if (avgPrice !== 0) {
    const change = (t1 / (t1 + t2) / avgPrice) * 100 - 100;
    return change;
  }
  return 0;
};

export const getSymbolFromTransaction = (transaction: string) => {
  let findSymbol = "";
  allStockData.every(function (item: any, index: number) {
    // Do your thing, then:
    findSymbol = item.name;
    if (transaction.includes(item.name)) return false;
    else return true;
  });

  return findSymbol;

  // .forEach((item: any) => {

  // })
};
