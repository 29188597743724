import { convertTextChatToHtml } from "../../utils/local";
import parse from "html-react-parser";
import { useRouter } from "next/router";
import { useAuth } from "../../context/AuthContext";
import SkeletonElement from "../SkeletonElement";
import { getNameSymbol } from "../../utils/stock";

export interface RightRecentTradesItemProps {
  item: IRightRecentTrades;
}

export interface IRightRecentTrades {
  _id: string;
  avatar: string;
  name: string;
  numShares: string;
  transaction: string;
  amount: string | number;
  createdAt: string;
  idHistory?: {
    stock?: string;
  };
}

const RightRecentTradesItem = ({ item }: RightRecentTradesItemProps) => {
  const router = useRouter();
  const { useOptionHome } = useAuth();
  const [optionHome, setOptionHome] = useOptionHome;

  const description = () => {
    const transactionArrayText = item?.transaction?.split(" ");
    if (
      transactionArrayText?.includes("Daily") ||
      transactionArrayText?.includes("Weekly") ||
      transactionArrayText?.includes("WEEKLY") ||
      transactionArrayText?.includes("DAILY") ||
      transactionArrayText?.includes("Monthly") ||
      transactionArrayText?.includes("MONTHLY") ||
      transactionArrayText?.includes("Metrics") // ||
      // transactionArrayText?.includes("MONTHLY")
    ) {
      // const symbol = transactionArrayText?.[2];
      // const color = transactionArrayText?.[3]

      if (transactionArrayText?.[0] == "Filled") {
        const type = transactionArrayText?.[1]; // Buy or Sell
        const symbol = transactionArrayText?.[3];
        const color = transactionArrayText?.[4];
        return `${type == "Buy" ? "Bought" : type == "Sell" ? "Sold" : ""} ${
          Math.round(Number(item?.numShares)) || "some"
        } ${color} $${getNameSymbol(symbol)} shares`; // Bought 20 Green $SPY shares
      }

      const symbol = transactionArrayText?.[2]?.split("").includes("-")
        ? transactionArrayText?.[3]
        : transactionArrayText?.[2]; // fix one bug save wrong
      const type = transactionArrayText?.[0]; // Buy or Sell or Fi
      const typeTime = transactionArrayText?.[1]; // Buy or Sell
      const color = transactionArrayText?.[2]?.split("").includes("-") // fix one bug save wrong
        ? transactionArrayText?.[4]
        : transactionArrayText?.[3];
      // const amount = item?.amount;

      // console.log({ transactionArrayText });

      const optionExtend = item?.transaction.includes("%")
        ? `${transactionArrayText?.[4]} `
        : "";
      return `${
        type == "Buy" ? "Bought" : type == "Sell" ? "Sold" : ""
      } ${Math.round(
        Number(item?.numShares)
      )} ${color} ${optionExtend}$${getNameSymbol(symbol)} shares`;
    } else {
      const symbol = transactionArrayText?.[1];
      const type = transactionArrayText?.[0]; // Buy or Sell
      const color = transactionArrayText?.[2];
      // const amount = item?.amount;
      // console.log({ transactionArrayText });
      return `${
        type == "Buy" ? "Bought" : type == "Sell" ? "Sold" : ""
      } ${Math.round(Number(item?.numShares))} ${color} $${getNameSymbol(
        symbol
      )} shares`;
    }
  };

  const handleClickRecentTrades = () => {
    const transactionArrayText = item?.transaction?.split(" ");
    const symbol = getNameSymbol(item?.idHistory?.stock);
    if (
      transactionArrayText.includes("Weekly") ||
      transactionArrayText.includes("WEEKLY")
    ) {
      setOptionHome("WEEKLY");
      router.push(`/weekly/${symbol}`);
    } else if (
      transactionArrayText.includes("Monthly") ||
      transactionArrayText.includes("MONTHLY")
    ) {
      setOptionHome("MONTHLY");
      router.push(`/monthly/${symbol}`);
    } else if (
      transactionArrayText.includes("Daily") ||
      transactionArrayText.includes("DAILY")
    ) {
      setOptionHome("DAILY");
      router.push(`/daily/${symbol}`);
    } else {
      router.push(`/metrics/${symbol}`);
    }
  };

  return (
    <>
      <div className="rightRecentTradesItem" onClick={handleClickRecentTrades}>
        {item?.avatar ? (
          <img
            className="avatar"
            src={
              item?.avatar?.startsWith("Group")
                ? `/Profile Avatars/${item?.avatar}`
                : `/Profile Avatars/Group 639.png`
            }
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              router.push(`/u/${item.name}`);
            }}
          />
        ) : (
          <SkeletonElement className="skeleton-trades-avatar" />
        )}
        {item?.avatar ? (
          <p className="text">
            {parse(convertTextChatToHtml(description()))} {}
          </p>
        ) : (
          <SkeletonElement
            className="skeleton-trades-text"
            style={{ width: Math.random() * (196 - 180) + 180 }}
          />
        )}
      </div>
    </>
  );
};

export default RightRecentTradesItem;
