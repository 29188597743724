import React from "react";
import clsx from "clsx";

export interface SkeletonElementProps {
  type?: string;
  theme?: "dark" | "light";
  className?: string;
  style?: Object;
}
export default function SkeletonElement({
  type,
  theme,
  className,
  style,
}: SkeletonElementProps) {
  const classTheme = theme ? `skeleton-${theme}` : "";
  const classThemeShimmer = theme ? `skeleton_shimmer-${theme}` : "";

  return (
    <div
      className={clsx("skeleton", type && type, classTheme, className)}
      style={style}
    >
      <div className={clsx("skeleton_shimmer", classThemeShimmer)}></div>
    </div>
  );
}
