import { useRouter } from "next/dist/client/router";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import googleIcon from "../../assets/img/google_icon.png";
import metaIcon from "../../assets/img/svg/meta.svg";
import { useAuth } from "../../context/AuthContext";
import { User } from "../../models";
import getFeatherClient from "../../utils/featherClient";
import ClosedImg from "../../assets/img/svg/close.svg";
import Button from "../../components/common/Button";
import { useSnackbar } from "notistack";
import validator from "validator";
import clsx from "clsx";

import { trackGoal } from "fathom-client";
import { CONTINUE_GOOGLE_CODE } from "../../utils/fathomCode";

type TypeOptionsAuth = "sign-up" | "sign-in";

export default function SignUp({ isModal }) {
  const router = useRouter();
  const { login, manualLogin, useIsShowPopup } = useAuth();
  const [
    isShowPopupLogin,
    setIsShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupSignUp,
    valueEmail,
    setValueEmail,
  ] = useIsShowPopup;
  const userService = getFeatherClient().service("users");
  const { enqueueSnackbar } = useSnackbar();
  const [optionAuth, setOptionAuth] = useState<TypeOptionsAuth>(
    router?.query?.option == "signin" ? "sign-in" : "sign-up"
  );
  const [isHover, setIsHover] = useState<boolean>(false);

  const [userInfo, setUserInfo] = useState<any>({
    avatar: ``,
    email: router?.query?.email !== undefined ? router?.query?.email : "",
    password: "",
  });

  useEffect(() => {
    const accessToken = router.query.access_token;
    const app = getFeatherClient();
    if (accessToken) {
      app.authentication.setAccessToken(accessToken);
    }
    login();
    setIsHover(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const handleClickSignUp = async () => {
    // console.log({ userInfo });
    if (!validator.isEmail(userInfo.email)) {
      enqueueSnackbar(`Email not valid`, {
        variant: "error",
      });
      return;
    } else if (userInfo.password == "") {
      enqueueSnackbar(`Password cannot be empty`, {
        variant: "error",
      });
      return;
    }
    setIsShowPopupSignUp(false);
    setIsShowPopupLogin(false);
    // return;
    try {
      const data = await userService
        .create(userInfo)
        .then(async (dataUser: any) => {
          handleClickLogin();
          enqueueSnackbar(`Welcome ${dataUser.name}`, {
            variant: "success",
          });
        });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };
  const handleClickLogin = async () => {
    await manualLogin(userInfo).then((checkOrError) => {
      if (checkOrError === "Login Done") router.push("/daily");
      else {
        enqueueSnackbar(checkOrError, {
          variant: "error",
        });
      }
      setIsShowPopupSignUp(false);
      setIsShowPopupLogin(false);
    });
  };
  const handleClickOptionsAuth = (opt: TypeOptionsAuth) => {
    setOptionAuth(opt);
  };
  const handleClickSignUpButton = () => {
    router.push(`${process.env.NEXT_PUBLIC_SERVER_URL}/oauth/google`);
    setIsShowPopupSignUp(false);
    setIsShowPopupLogin(false);
  };

  useEffect(() => {
    setUserInfo({ ...userInfo, email: valueEmail });
  }, [valueEmail]);

  return (
    <div className="auth" style={isModal ? {} : { display: "none" }}>
      {/* <div className="rawQuestion"></div> */}
      {/* <div
        className={clsx(
          "auth_options",
          statusAuth === StatusAuth.signIn
            ? "auth_options-sign-in"
            : "auth_options-sign-up"
        )}
      >
        <Button
          className="btnSignUp"
          text="Sign up"
          onClick={handleStatusAuthSignUp}
          active={statusAuth === StatusAuth.signUp}
        />
        <Button
          className="btnSignIn"
          text="Sign in"
          onClick={handleStatusAuthSignIn}
          active={statusAuth === StatusAuth.signIn}
        />
      </div> */}
      {/* <div className="auth_options">
        <div
          className={clsx(
            "optionsWrapperAuth",
            `optionsWrapperAuth-${optionAuth}`
          )}
        >
          <Button
            className="btnOptAuth"
            text="Sign up"
            onClick={() => handleClickOptionsAuth("sign-up")}
            active={optionAuth == "sign-up"}
          />
          <Button
            className="btnOptAuth"
            text="Sign in"
            onClick={() => handleClickOptionsAuth("sign-in")}
            active={optionAuth == "sign-in"}
          />
        </div>
      </div> */}
      <div className="info_title">
        <p>
          {isShowPopupSignUp ? "Get Early Access" : "Login to StockBet"}
        </p>
      </div>
      <div>
        <div className="info_title-email">
          <p>Email address</p>
        </div>
        <div className="info_input">
          <div className="inputWrapper">
            <input
              value={isShowPopupSignUp ? valueEmail : userInfo.email}
              type="text"
              onChange={(e) => {
                e.stopPropagation();
                setUserInfo({
                  ...userInfo,
                  email: e.target.value,
                  name: e.target.value.split("@")[0],
                });
                setValueEmail(e.target.value);
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </div>
      <div className="info">
        <div className="info_title-password">
          <p>{isShowPopupSignUp ? "Create Password" : "Password"}</p>
        </div>
        <div className="info_input">
          <div className="inputWrapper">
            <input
              value={userInfo.password}
              type="password"
              onChange={(e) => {
                e.stopPropagation();
                setUserInfo({
                  ...userInfo,
                  password: e.target.value,
                });
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </div>

      {/* {isShowPopupSignUp && (
        <div className="info info-checkBox">
          <p>
            <input type="checkbox" id="test1" />
            <label htmlFor="test1">
              I understand that StockBet is currently still in alpha and not
              liable for any potential bugs, issues, or losses that may occur.
            </label>
          </p>
        </div>
      )} */}

      <div className="info">
        <div className="info_buttons">
          <Button
            className="btn-sign-up"
            text={isShowPopupLogin ? "Log In" : "Get Early Access"}
            onClick={(e) => {
              e.stopPropagation();
              if (isShowPopupLogin) {
                console.log("Log In");
                return handleClickLogin();
              }
              console.log("Get Early Access");
              return handleClickSignUp();
            }}
          />
        </div>
        <div className="info_buttons">
          <button
            className="auth__button"
            onClick={(e) => {
              e.stopPropagation();
              handleClickSignUpButton();
              trackGoal(CONTINUE_GOOGLE_CODE, 0);
            }}
          >
            <div className="google-icon">
              <Image src={googleIcon} alt="" width="100%" height="100%" />
            </div>
            <span> {"Continue with Google"}</span>
          </button>
          <button
            className="auth__button_meta"
            onClick={(e) => {
              e.stopPropagation();
              setIsHover(true);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setIsHover(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsHover(false);
            }}
          >
            {!isHover && (
              <div className="icon_meta">
                <Image src={metaIcon} alt="" width="100%" height="100%" />
              </div>
            )}
            <span> {!isHover ? "Connect to MetaMask" : "Coming soon"}</span>
          </button>
        </div>
      </div>
      <div
        className="info_close"
        onClick={(e) => {
          e.stopPropagation();
          setIsShowPopupLogin(false);
          setIsShowPopupSignUp(false);
        }}
      >
        <Button className="info_close_button" text="">
          <img src={ClosedImg.src} alt="" />
        </Button>
      </div>
    </div>
  );
}
