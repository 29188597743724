import React from "react";
import clsx from "clsx";
import { ReactElement } from "react";

export interface PropsTable {
  text?: String;
  className?: String;
  active?: boolean;
  icon?: ReactElement;
  children?: ReactElement;
  onClick?: ((someThing?: any) => void) | any;
  onMouseOver?;
  onMouseLeave?;
  style?: any;
}

const Button = (props: PropsTable) => {
  const {
    text,
    onClick,
    onMouseOver,
    onMouseLeave,
    active,
    className,
    icon,
    ...input
  } = props;
  return (
    <button
      className={clsx(
        "commonButton",
        className ? className : "",
        active ? "active" : ""
      )}
      onClick={onClick ? onClick : undefined}
      onMouseOver={onMouseOver ? onMouseOver : undefined}
      onMouseLeave={onMouseLeave ? onMouseLeave : undefined}
      {...input}
    >
      {icon && <span>{icon}</span>}
      {text && <p>{text}</p>}
      {props.children && props.children}
    </button>
  );
};

export default Button;
