// left: 37, up: 38, right: 39, down: 40,

// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys: object = { 37: 1, 38: 1, 39: 1, 40: 1 };

const preventDefault = (e: React.FormEvent<HTMLInputElement>) => {
  e.preventDefault();
};

export const scrollToTop = (elm?: HTMLDivElement): void => {
  if (elm) {
    return elm.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
  return window.scroll({ top: 0, left: 0, behavior: "smooth" });
};

export const scrollToTopNormal = (elm?: HTMLDivElement): void => {
  if (elm) {
    return elm.scroll({ top: 0, left: 0, behavior: "auto" });
  }
  return window.scroll({ top: 0, left: 0, behavior: "auto" });
};

export const scrollToBottom = (elm: HTMLDivElement) => {
  if (elm) {
    const top = elm.scrollHeight - elm.clientHeight + 90;
    elm.scroll({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const scrollToByRatio = (elm: HTMLElement, ratio: number) => {
  if (elm && ratio) {
    elm.scroll({
      top: (elm.scrollHeight - elm.clientHeight) * ratio,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const scrollToByRatioNormal = (elm: HTMLElement, ratio: number) => {
  if (elm && ratio) {
    elm.scroll({
      top: (elm.scrollHeight - elm.clientHeight) * ratio,
      left: 0,
      behavior: "auto",
    });
  }
};

export const scrollToChildElement = (
  elmWrapper: HTMLElement,
  elmChild: HTMLElement
) => {
  if (elmWrapper && elmChild) {
    elmWrapper.scroll({
      top: elmChild.offsetTop - elmWrapper.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const disableTouchElms = (elms: HTMLDivElement[]) => {
  elms.forEach((elm: HTMLDivElement) => {
    if (elm) {
      elm.addEventListener("touchmove", function (e) {
        e.preventDefault();
      });
    }
  });
};

export const removeEventDisableTouchElms = (elms: HTMLDivElement[]) => {
  elms.forEach((elm: HTMLDivElement) => {
    if (elm) {
      elm.removeEventListener("touchmove", () => {});
    }
  });
};

export const checkVisibleElm = (elm: HTMLElement) => {
  if (!elm) {
    return false;
  }
  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  );
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
};
