import React, { useEffect, useState } from "react";

import { useAuth } from "../../context/AuthContext";
import { useRouter } from "next/dist/client/router";
import Trades from "../../pages/trades";
import Social from "../../pages/social";

export default function RightBar() {
  // const { user, search, setSearch } = useAuth();
  const router = useRouter();

  return (
    <div className="rightBar">
      {/* <AccountInfo isCallUser={true} /> */}
      {/* <div className="rightBar_social">
        <div className="titleChat">Discussion Board</div>

        <Social isBar={true} />
      </div> */}

      <Trades isBar={true} />
    </div>
  );
}
