import React, { useEffect, useState } from "react";
import { useRouter } from "next/dist/client/router";
import { useAuth } from "../../context/AuthContext";

import { scrollToTopNormal } from "../../utils/localDom";
import RightRecentTradesItem, {
  IRightRecentTrades,
} from "../../components/RightBar/RightRecentTradesItem";
import {
  betRestAPI,
  getStockAPI,
  recentTradesApi,
  stockRestAPI,
} from "../../utils/restClient";
import getFeatherClient from "../../utils/featherClient";
import clsx from "clsx";

export default function Trades({ isBar }) {
  const { useRecentTrades } = useAuth();

  const { recentTrades, setRecentTrades } = useRecentTrades;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [recentTrades, setRecentTrades] = useState<IRightRecentTrades[]>([]);

  const router = useRouter();
  const getStockService = getFeatherClient().service("get-stock");

  useEffect(() => {
    scrollToTopNormal(
      (document.querySelector(".page-content") as HTMLDivElement) || undefined
    );
  }, []);

  useEffect(() => {
    //
    setIsLoading(true);
    recentTradesApi
      .find({})
      .then((resRecentTrades) => {
        //
        console.log({ resRecentTrades });
        setRecentTrades(resRecentTrades?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log({ err });
      });

    // event realtime
    console.log("create event recent realtime");
    // betRestAPI.on("created", async (realTimeNewBet1: any) => {
    //   console.log("new bet created", realTimeNewBet1);
    //   // if (user._id !== data.user)
    //   // setRecentTrades(resRecentTrades?.data);
    // });

    getStockService.on("new-bet", async (realTimeNewBet: any) => {
      console.log({ realTimeNewBet });
      setRecentTrades((prevRecentTrades) => [
        realTimeNewBet.data,
        ...prevRecentTrades,
      ]);
    });

    return () => {
      // Clean up listeners
      betRestAPI.removeListener("created");
      getStockService.removeListener("new-bet");
    };
  }, []);

  return (
    <div className={clsx("trades", isBar ? "trades-bar" : "trades-mobile")}>
      {!isBar && (
        <div className="trades_title">
          <p>Recent Trades</p>
        </div>
      )}
      <div className="trades_lists">
        {isLoading
          ? Array.from(Array(30).keys())?.map((item: any, index: number) => (
              <RightRecentTradesItem key={index} item={item} />
            ))
          : recentTrades?.map((item: IRightRecentTrades, index: number) => (
              <RightRecentTradesItem key={index} item={item} />
            ))}
      </div>
    </div>
  );
}
