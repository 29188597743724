import moment from "moment";
import { TypeTimeOptionsActive } from "../components/symbol/BuySellBox";
import { IDataChart } from "../components/symbol/ChartSymbolItem/ChartSymbol";
import { TypeChartActive } from "../components/symbol/SymbolPage";
import { TypeOptionsHome } from "../context/AuthContext";

const charNumbers: String[] = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const charNumbersDecimal: String[] = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  ".",
];

export const onlyNumber = (text: string): string => {
  return text
    .split("")
    .filter((char) => charNumbers.includes(char))
    .join("");
};

export const onlyNumberDecimal = (textInput: string): number | string => {
  // check decimal
  let text: number | string = textInput;
  if (textInput == "") return "";
  if (textInput == "0") return "0";
  if (textInput == "0.") return "0.";
  const arrText = String(text)?.split("");
  // const isDecimal = text.includes(".");

  if (arrText[0] == "0" && arrText[1] == "0") {
    text = arrText.slice(1).join("");
  }
  if (arrText[0] == "0" && Number(arrText[1]) > 0) {
    text = arrText.slice(1).join("");
  }

  if (text.includes(".")) {
    const indexDecimal = arrText.findIndex((char: string) => char == ".");
    text = arrText.slice(0, indexDecimal + 3).join("");
  }

  const text2 = String(text)
    .split("")
    .filter((char, index) => {
      return charNumbersDecimal.includes(char);
    })
    .join("");

  if (
    text2.split("").filter((char: string | number) => char == ".").length > 1
  ) {
    // console.log(text2.split(""), text2.split("").slice(0, text2.length -1));
    return text2
      .split("")
      .slice(0, text2.length - 1)
      .join("");
  }
  return text2;
};

export const convertTextChat = (text: string): string => {
  return text
    .split("\n")
    .join("")
    .split(" ")
    .map((text) => (text[0] == "$" ? `${text.toUpperCase()}` : text))
    .join(" ");
};

export const convertTextChatToHtml = (text: string): string => {
  return (
    text
      .split(" ")
      .map((text) =>
        text[0] == "$"
          ? `<span class="dola">${text.toUpperCase()}</span>`
          : `<span>${text}</span>`
      )
      // .filter((char) => charNumbers.includes(char))
      .join(" ")
  );
};

export const convertTimeFromNow = (date): { time: string; status: boolean } => {
  const time = moment(date).fromNow();
  let resultTime = time;
  let statusCheck = true;

  if (time.includes("a few seconds ago")) {
    resultTime =
      String(
        ((moment().valueOf() - moment(date).valueOf()) / 1000).toFixed(0)
      ) + "s";
  } else if (time.includes("a minute ago")) {
    resultTime = "1m";
  } else if (time.includes("minutes ago")) {
    resultTime = time.split(" ")[0] + "m";
  } else if (time.includes("an hour ago")) {
    resultTime = "1h";
  } else if (time.includes("hours ago")) {
    resultTime = time.split(" ")[0] + "h";
  } else if (time.includes("a day ago")) {
    resultTime = "1d";
    statusCheck = false;
  } else if (time.includes("days ago")) {
    if (Number(time.split(" ")[0]) < 7) resultTime = time.split("")[0] + "d";
    else {
      resultTime = moment(date).format("MM/DD/YY");
    }
    statusCheck = false;
  }
  return { time: resultTime, status: statusCheck };
};

export const checkImage = (item: string): boolean => {
  const temp = ["png", "jpg", "jpeg", "gif", "svg"];
  if (item && temp.includes(item.split(".").splice(-1, 1)[0].toLowerCase())) {
    return true;
  }
  return false;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const capFirst = (str: string) => {
  if (!str) return "";
  return str[0].toUpperCase() + str.toLowerCase().slice(1);
};

export const timeOptionOfBet = ({
  timeOption,
  typeChartActive,
  symbol,
}: {
  timeOption: TypeOptionsHome | undefined;
  typeChartActive: TypeChartActive;
  symbol?: string;
}): TypeTimeOptionsActive => {
  if (timeOption == "METRICS") {
    return timeOption;
  } else if (symbol && ["XLK", "XLF", "XLE", "XLP"].includes(symbol)) {
    return `${timeOption}_SECTOR` as TypeTimeOptionsActive;
  } else {
    const percent = percentOfBet({ timeOption });
    const text =
      typeChartActive == "GREEN_OR_RED"
        ? timeOption
        : `${timeOption}_${typeChartActive}_${percent}`; // ex:  "DAILY_HIGHER_THAN_1", //  "DAILY_LOWER_THAN_1",

    return text as TypeTimeOptionsActive;
  }
};

export const textTimeOptionOfBet = ({
  timeOptionExtend,
}: {
  timeOptionExtend: TypeTimeOptionsActive;
}) => {
  if (timeOptionExtend == "METRICS") {
    return "Metrics";
  }
  const percent = percentOfBet({ timeOption: timeOptionExtend });
  const textTime = capFirst(timeOptionExtend?.split("_")?.[0]);
  return textTime;
};

export const getTimeOptionFromTrade = ({
  transactionText,
}: {
  transactionText: string;
}) => {
  const textTrade = transactionText?.toLocaleUpperCase() as string;
  const isOptionExtend = textTrade.includes("%"); // +1% ....
  const getTimeOption: TypeTimeOptionsActive = !isOptionExtend
    ? textTrade.includes("DAILY")
      ? "DAILY"
      : textTrade.includes("WEEKLY")
      ? "WEEKLY"
      : textTrade.includes("MONTHLY")
      ? "MONTHLY"
      : "METRICS"
    : textTrade.includes("DAILY") && textTrade.includes("+1%")
    ? "DAILY_HIGHER_THAN_1"
    : textTrade.includes("DAILY") && textTrade.includes("-1%")
    ? "DAILY_LOWER_THAN_1"
    : textTrade.includes("WEEKLY") && textTrade.includes("+3%")
    ? "WEEKLY_HIGHER_THAN_3"
    : textTrade.includes("WEEKLY") && textTrade.includes("-3%")
    ? "WEEKLY_LOWER_THAN_3"
    : textTrade.includes("MONTHLY") && textTrade.includes("+5%")
    ? "MONTHLY_HIGHER_THAN_5"
    : textTrade.includes("MONTHLY") && textTrade.includes("-5%")
    ? "MONTHLY_LOWER_THAN_5"
    : "METRICS";

  return getTimeOption;
};

export const increaseOrYes = ({
  timeOption,
}: {
  timeOption: TypeTimeOptionsActive;
}) => {
  return timeOption == "DAILY" ||
    timeOption == "WEEKLY" ||
    timeOption == "MONTHLY"
    ? "INCREASE"
    : "YES";
};

export const decreaseOrNo = ({
  timeOption,
}: {
  timeOption: TypeTimeOptionsActive;
}) => {
  return timeOption == "DAILY" ||
    timeOption == "WEEKLY" ||
    timeOption == "MONTHLY"
    ? "DECREASE"
    : "NO";
};

export const greenOrYes = ({
  timeOption,
}: {
  timeOption: TypeTimeOptionsActive;
}) => {
  return timeOption == "DAILY" ||
    timeOption == "WEEKLY" ||
    timeOption == "MONTHLY"
    ? "Green"
    : "Yes";
};

export const redOrNo = ({
  timeOption,
}: {
  timeOption: TypeTimeOptionsActive;
}) => {
  return timeOption == "DAILY" ||
    timeOption == "WEEKLY" ||
    timeOption == "MONTHLY"
    ? "Red"
    : "No";
};

export const percentOfBet = ({
  timeOption,
}: {
  timeOption: TypeTimeOptionsActive | undefined;
}) => {
  return timeOption?.includes("DAILY")
    ? 1
    : timeOption?.includes("WEEKLY")
    ? 3
    : timeOption?.includes("MONTHLY")
    ? 5
    : "";
};


export const convertNewChart = (reqDataChart) => {
  return reqDataChart.map((item: IDataChart) => ({
    ...item,
    greenDisplay:
      item.green > 0.65 ? 0.65 : item.green < 0.35 ? 0.35 : item.green,
  }));
};