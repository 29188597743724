import { useRouter } from "next/dist/client/router";
import { ReactElement, useState } from "react";

import ImageViewer from "react-simple-image-viewer";
import FooterMobile from "../components/FooterMobile";
import RightBar from "../components/RightBar";
import SideBar from "../components/SideBar";
import TopHeader from "../components/TopHeader";
import Footer from "../components/Footer";

import { useAuth } from "../context/AuthContext";
import Auth from "../pages/auth";

import ShareImage from "../components/user/ShareImage";

export interface MainLayout {
  children: ReactElement;
}

const MainLayout = ({ children }: MainLayout) => {
  const { useImageViewer, useIsShowPopup, useDataShare } = useAuth();
  const [isViewerOpen, setIsViewerOpen, imageViewer, setImageViewer] =
    useImageViewer;
  const { dataShare, setDataShare } = useDataShare;
  const [
    isShowPopupLogin,
    setIsShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupSignUp,
  ] = useIsShowPopup;
  const router = useRouter();
  const style =
    router.pathname.split("/")[1] !== "metrics"
      ? router.pathname.split("/")[1]
      : router.pathname.split("/")[2]
      ? "metrics-symbol"
      : "metrics";
  const isLanding = router.pathname === "/";

  const closeImageViewer = () => {
    setIsViewerOpen(false);
    setImageViewer([]);
  };

  if (!isLanding)
    return (
      <div className="container-page">
        <div className="container-center-horizontal">
          <div
            id="screenId"
            className={`${style}-custom screen`}
            style={{
              opacity: isShowPopupLogin || isShowPopupSignUp ? 0.3 : 1,
            }}
          >
            <SideBar />
            {/* <div className="page"> */}
            <div className="page-content">
              <TopHeader />
              {children}
              {/* </div> */}

              {/* <div
                className="page-content"
                style={{
                  opacity: isShowPopupLogin || isShowPopupSignUp ? 0.3 : 1,
                }}
              >
                {children}
              </div> */}
              <Footer />
            </div>
            <RightBar />
          </div>
        </div>
        <FooterMobile />
        {(isShowPopupLogin || isShowPopupSignUp) && (
          <div
            className="page-auth"
            onClick={() => {
              // setIsShowPopupLogin(false);
              // setIsShowPopupSignUp(false);
            }}
          >
            <div className="auth-container">
              <Auth isModal={true} />
            </div>
          </div>
        )}
        {/* {isViewerOpen && imageViewer.length > 0 && (
          <ImageViewer
            src={imageViewer}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )} */}
        {dataShare?.isDisplay && <ShareImage />}
      </div>
    );

  return (
    <>
      <div className="landing-page">{children}</div>
    </>
  );
};

export default MainLayout;
