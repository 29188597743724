import { useRouter } from "next/dist/client/router";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "../models";
import getFeatherClient from "../utils/featherClient";
import { userRest } from "../utils/restClient";
import { disableTouchElms } from "../utils/localDom";
import { IRightRecentTrades } from "../components/RightBar/RightRecentTradesItem";
import { IUserWinLoss } from "../components/top-moves";
import { TypeTimeOptionsActive } from "../components/symbol/BuySellBox";

type authContextType = {
  useTheme: any;
  isLogged: boolean;
  user: User;
  isLoadingFetchUser: boolean;
  setUser: any;
  login: () => void;
  isFetchLogin: boolean;
  manualLogin: (userInfo: any) => Promise<any> | any;
  logout: () => void;
  fetchUserInfo: () => void;
  updateUsername: (name: string) => void;
  checkLogin: () => boolean;
  search: string;
  setSearch: any;
  useChat: Array<any>;
  useImageViewer: Array<any>;
  useIsShowPopup: Array<any>;
  useOptionHome: Array<any>;
  useWinLoss: {
    isWin?: boolean;
    setIsWin?: any;
    //
    usersWinDaily?: IUserWinLoss[];
    setUsersWinDaily?: any;
    usersLossDaily?: IUserWinLoss[];
    setUsersLossDaily?: any;
    //
    usersWinWeekly?: IUserWinLoss[];
    setUsersWinWeekly?: any;
    usersLossWeekly?: IUserWinLoss[];
    setUsersLossWeekly?: any;
    //
    usersWinMonthly?: IUserWinLoss[];
    setUsersWinMonthly?: any;
    usersLossMonthly?: IUserWinLoss[];
    setUsersLossMonthly?: any;
    //
    isFetchUsersWinLoss?: boolean;
    setIsFetchUsersWinLoss?: any;
  };
  //
  useRecentTrades: {
    recentTrades?: IRightRecentTrades[];
    setRecentTrades?: any;
  };
  useDataShare: {
    dataShare?: IDataShare;
    setDataShare?: any;
  };
  useNotifications: {
    isShowNotifications?: boolean;
    setIsShowNotifications?: any;
    isReadNotifications?: boolean;
    setIsReadNotifications?: any;
  };
};
const authContextDefaultValues: authContextType = {
  useTheme: null,
  isLogged: true,
  user: {},
  isLoadingFetchUser: true,
  setUser: () => {},
  login: () => {},
  isFetchLogin: false,
  logout: () => {},
  manualLogin: () => {},
  updateUsername: (name: string) => {},
  fetchUserInfo: () => {},
  checkLogin: () => false,
  search: "",
  setSearch: () => {},
  useChat: [],
  useImageViewer: [],
  useIsShowPopup: [],
  useOptionHome: [],
  useWinLoss: {},
  useRecentTrades: {},
  useDataShare: {},
  useNotifications: {},
};
const AuthContext = createContext<authContextType>(authContextDefaultValues);

export function useAuth() {
  return useContext(AuthContext);
}
type Props = {
  children: ReactNode;
};
export type TypeOptionsHome = "DAILY" | "WEEKLY" | "MONTHLY" | "METRICS";
export interface IDataShare {
  isDisplay: boolean;
  image: string;
  url: string;
  symbol: string;
  date: "";
  timeOption: TypeTimeOptionsActive | "";
  price: 0;
  status: "Green" | "Red" | "Yes" | "No" | "";
  result: "Win" | "Loss" | "";
}

export function AuthProvider({ children }: Props) {
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(true);
  const [optionHome, setOptionHome] = useState<TypeOptionsHome>("DAILY");
  const [user, setUser] = useState<User>({});
  const [isWin, setIsWin] = useState<boolean>(true);
  const [usersWinDaily, setUsersWinDaily] = useState<Array<any>>([]);
  const [usersLossDaily, setUsersLossDaily] = useState<Array<any>>([]);
  const [usersWinWeekly, setUsersWinWeekly] = useState<Array<any>>([]);
  const [usersLossWeekly, setUsersLossWeekly] = useState<Array<any>>([]);
  const [usersWinMonthly, setUsersWinMonthly] = useState<Array<any>>([]);
  const [usersLossMonthly, setUsersLossMonthly] = useState<Array<any>>([]);

  const [isFetchUsersWinLoss, setIsFetchUsersWinLoss] =
    useState<boolean>(false);
  const [isFetchLogin, setIsFetchLogin] = useState<boolean>(false);

  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [isLoadingFetchUser, setIsLoadingFetchUser] = useState(true);
  const [search, setSearch] = useState<string>("");
  const [messagesData, setMessagesData] = useState<Array<any>>([]);
  const [lastTime, setLastTime] = useState<string>("");
  const [isFetchMoreData, setIsFetchMoreData] = useState<boolean>(true);

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageViewer, setImageViewer] = useState<Array<string>>([]);
  const [isShowPopupLogin, setIsShowPopupLogin] = useState(false);
  const [isShowPopupSignUp, setIsShowPopupSignUp] = useState(false);
  const [valueEmail, setValueEmail] = useState("");

  const [recentTrades, setRecentTrades] = useState<IRightRecentTrades[]>([]);

  const [dataShare, setDataShare] = useState<IDataShare>({
    isDisplay: false,
    image: "",
    url: "",
    symbol: "",
    date: "",
    timeOption: "",
    price: 0,
    status: "",
    result: "",
  });

  const [isShowNotifications, setIsShowNotifications] =
    useState<boolean>(false);
  const [isReadNotifications, setIsReadNotifications] = useState<boolean>(true);

  const useChat = [
    messagesData,
    setMessagesData,
    lastTime,
    setLastTime,
    isFetchMoreData,
    setIsFetchMoreData,
  ];
  const useIsShowPopup = [
    isShowPopupLogin,
    setIsShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupSignUp,
    valueEmail,
    setValueEmail,
  ];
  const useImageViewer = [
    isViewerOpen,
    setIsViewerOpen,
    imageViewer,
    setImageViewer,
  ];
  const useWinLoss = {
    isWin,
    setIsWin,
    usersWinDaily,
    setUsersWinDaily,
    usersLossDaily,
    setUsersLossDaily,
    usersWinWeekly,
    setUsersWinWeekly,
    usersLossWeekly,
    setUsersLossWeekly,
    usersWinMonthly,
    setUsersWinMonthly,
    usersLossMonthly,
    setUsersLossMonthly,
    isFetchUsersWinLoss,
    setIsFetchUsersWinLoss,
  };
  const useTheme = { isDarkTheme, setIsDarkTheme };
  const useOptionHome = [optionHome, setOptionHome];
  const useRecentTrades = { recentTrades, setRecentTrades };
  const useDataShare = { dataShare, setDataShare };

  const useNotifications = {
    isShowNotifications,
    setIsShowNotifications,
    isReadNotifications,
    setIsReadNotifications,
  };

  const app = getFeatherClient();
  const router = useRouter();

  const manualLogin = async (userInfo: any) => {
    try {
      setIsLoadingFetchUser(true);
      return await app.reAuthenticate();
    } catch (error) {
      return await app
        .authenticate({
          strategy: "local",
          email: userInfo.email,
          password: userInfo.password,
        })
        .then(() => {
          login();
          return "Login Done";
        })
        .catch((error) => {
          setIsLoadingFetchUser(false);
          setIsFetchLogin(true);
          return error.message;
        });
    }
  };
  const login = () => {
    app
      .reAuthenticate()
      .then(() => {
        setIsLoadingFetchUser(true);
        app
          .get("authentication")
          .then((data) => {
            const user: User = data.user;
            setUser(user);
            setIsLogged(true);
            setIsFetchLogin(true);
            let path = router.pathname;
            if (path === "/" || path === "/auth") {
              user.admin ? (path = "/home") : (path = "/home");
              router.push(path);
            }
            // router.push(path);
            setIsLoadingFetchUser(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoadingFetchUser(false);
            setIsFetchLogin(true);
          });
      })
      .catch((err) => {
        setIsLoadingFetchUser(false);
        console.log("reAuthenticate error", err);
        setIsFetchLogin(true);
      });
  };

  const logout = async () => {
    await app.logout();
    setIsLogged(false);
    setUser({});
    // Please display the Dark Mode toggle to non-users
    const rootElm = document.documentElement as HTMLElement;
    rootElm.dataset.theme = "";
  };

  const checkLogin = () => {
    return app
      .reAuthenticate()
      .then(() => true)
      .catch((err) => false);
  };

  const updateUsername = async (name: string) => {
    let token: string;
    try {
      token = await app.authentication.getAccessToken();
    } catch (error) {
      console.log("Error: ", error);
      return;
    }
    const data = await userRest.patch(
      user._id,
      { name },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setUser(data);
  };
  const fetchUserInfo = async () => {
    const userService = getFeatherClient().service("users");
    let token: string;
    setIsLoadingFetchUser(true);
    try {
      token = await app.authentication.getAccessToken();
      setIsLoadingFetchUser(false);
    } catch (error) {
      console.log("Error: ", error);
      setIsLoadingFetchUser(false);
      return;
    }
    try {
      const res = await userService.find(
        { query: { _id: user._id } }
        // { header: { Authorization: `Bearer ${token}` } }
      );
      setUser(res.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const value = {
    useTheme,
    isLogged,
    user,
    isLoadingFetchUser,
    setUser,
    login,
    isFetchLogin,
    manualLogin,
    logout,
    updateUsername,
    fetchUserInfo,
    checkLogin,
    search,
    setSearch,
    useChat,
    useImageViewer,
    useIsShowPopup,
    useOptionHome,
    useWinLoss,
    useRecentTrades,
    useDataShare,
    useNotifications,
  };

  useEffect(() => {
    login();
    // fix Scrolling does not work sometimes
    const headElm = document.querySelector(".header");
    const sidebarElm = document.querySelector(".sidebar");
    disableTouchElms([headElm, sidebarElm] as HTMLDivElement[]);
  }, []);

  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </>
  );
}
