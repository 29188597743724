import clsx from "clsx";
import { useRouter } from "next/dist/client/router";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import avatar from "../../assets/img/avatar.png";
import logo from "../../assets/img/newLogo.png";
import userIcon from "../../assets/img/user_icon.png";
import AccountInfo from "../AccountInfo";
import SkeletonElement from "../SkeletonElement";
import { useAuth } from "../../context/AuthContext";

import { menu } from "../FooterMobile";

export default function SideBar() {
  const {
    useTheme,
    user,
    isFetchLogin,
    isLoadingFetchUser,
    isLogged,
    useIsShowPopup,
    useOptionHome,
  } = useAuth();
  const { isDarkTheme, setIsDarkTheme } = useTheme;
  const [
    isShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupLogin,
    setIsShowPopupSignUp,
  ] = useIsShowPopup;
  const [optionHome] = useOptionHome;
  const router = useRouter();
  const [menus, setMenus] = useState<any>([]);

  const handleChangeTheme = () => {
    const rootElm = document.documentElement as HTMLElement;
    if (rootElm) {
      rootElm.dataset.theme = !isDarkTheme ? "" : "light";
      localStorage.setItem("theme", !isDarkTheme ? "" : "light");
    }
    setIsDarkTheme(!isDarkTheme);
    // changeTheme at Bet detail
    // const btnChangeThemeChartElm = document.querySelector(
    //   "#btnChangeThemeChartId"
    // ) as HTMLElement;
    // btnChangeThemeChartElm && btnChangeThemeChartElm.click();
  };

  useEffect(() => {
    const menus = user.admin
      ? [
          {
            name: "Markets",
            href: "/daily",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="29"
                height="29"
                viewBox="0 0 29 29"
              >
                <defs>
                  <clipPath id="clipPath">
                    <rect
                      id="Rectangle_16"
                      data-name="Rectangle 16"
                      width="29"
                      height="29"
                      transform="translate(-0.315)"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Home"
                  transform="translate(0.315)"
                  clipPath="url(#clipPath)"
                >
                  <g
                    id="Group_1060"
                    data-name="Group 1060"
                    transform="translate(-0.204 0.311)"
                  >
                    <path
                      id="Path_151"
                      data-name="Path 151"
                      d="M0,0H28.224V28.224H0Z"
                      fill="none"
                    />
                    <path
                      id="Path_152"
                      data-name="Path 152"
                      d="M5.352,13.584H3L13.584,3,24.168,13.584H21.816"
                      transform="translate(0.528 0.528)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Path_153"
                      data-name="Path 153"
                      d="M5,12v8.231a2.352,2.352,0,0,0,2.352,2.352h11.76a2.352,2.352,0,0,0,2.352-2.352V12"
                      transform="translate(0.88 2.112)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Path_154"
                      data-name="Path 154"
                      d="M9,22.408V15.352A2.352,2.352,0,0,1,11.352,13H13.7a2.352,2.352,0,0,1,2.352,2.352v7.056"
                      transform="translate(1.584 2.288)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </g>
                </g>
              </svg>
            ),
          },
          {
            name: "Portfolio",
            href: `/u/${user.name}`,
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="29"
                height="32"
                viewBox="0 0 29 32"
              >
                <defs>
                  <clipPath id="clipPath">
                    <rect
                      id="Rectangle_18"
                      data-name="Rectangle 18"
                      width="29"
                      height="32"
                      transform="translate(-0.315 -0.033)"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Portfolio"
                  transform="translate(0.315 0.033)"
                  clipPath="url(#clipPath)"
                >
                  <g
                    id="Group_1059"
                    data-name="Group 1059"
                    transform="translate(0.229 1.147)"
                  >
                    <path
                      id="Path_158"
                      data-name="Path 158"
                      d="M0,0H29.568V29.568H0Z"
                      fill="none"
                    />
                    <path
                      id="Path_159"
                      data-name="Path 159"
                      d="M20.016,8.928v-3.7A1.232,1.232,0,0,0,18.784,4H6.464a2.464,2.464,0,1,0,0,4.928H21.248A1.232,1.232,0,0,1,22.48,10.16v3.7m0,4.928v3.7a1.232,1.232,0,0,1-1.232,1.232H6.464A2.464,2.464,0,0,1,4,21.248V6.464"
                      transform="translate(0.928 0.928)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Path_160"
                      data-name="Path 160"
                      d="M21.392,12v4.928H16.464a2.464,2.464,0,1,1,0-4.928h4.928"
                      transform="translate(3.248 2.785)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </g>
                </g>
              </svg>
            ),
          },
          {
            name: "Admin orders",
            href: "/admin/stocks",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="29"
                height="29"
                viewBox="0 0 29 29"
              >
                <defs>
                  <clipPath id="clipPath">
                    <rect
                      id="Rectangle_17"
                      data-name="Rectangle 17"
                      width="29"
                      height="29"
                      transform="translate(-0.315 0.494)"
                      fill="#fff"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Money_Icon"
                  data-name="Money Icon"
                  transform="translate(0.315 -0.494)"
                  clipPath="url(#clipPath)"
                >
                  <g
                    id="Group_1062"
                    data-name="Group 1062"
                    transform="translate(0.664 1.714)"
                  >
                    <path
                      id="Path_224"
                      data-name="Path 224"
                      d="M0,0H28.317V28.317H0Z"
                      fill="rgba(0,0,0,0)"
                    />
                    <rect
                      id="Rectangle_211"
                      data-name="Rectangle 211"
                      width="17.304"
                      height="11.012"
                      rx="2"
                      transform="translate(9.155 11.012)"
                      fill="rgba(0,0,0,0)"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <ellipse
                      id="Ellipse_114"
                      data-name="Ellipse 114"
                      cx="2.36"
                      cy="2.36"
                      rx="2.36"
                      ry="2.36"
                      transform="translate(15.447 14.158)"
                      fill="rgba(0,0,0,0)"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Path_225"
                      data-name="Path 225"
                      d="M19.518,9.719V7.36A2.36,2.36,0,0,0,17.158,5H5.36A2.36,2.36,0,0,0,3,7.36v7.079A2.36,2.36,0,0,0,5.36,16.8h2.36"
                      transform="translate(0.54 0.899)"
                      fill="rgba(0,0,0,0)"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </g>
                </g>
              </svg>
            ),
          },
          {
            name: "Withdraw",
            href: "/admin/withdraw",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#fff"
              >
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <g>
                  <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8c0-4.41,3.59-8,8-8 s8,3.59,8,8C20,16.41,16.41,20,12,20z M12.89,11.1c-1.78-0.59-2.64-0.96-2.64-1.9c0-1.02,1.11-1.39,1.81-1.39 c1.31,0,1.79,0.99,1.9,1.34l1.58-0.67c-0.15-0.44-0.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6,0.56-2.62,2.85-2.62,2.96 c0,2.27,2.25,2.91,3.35,3.31c1.58,0.56,2.28,1.07,2.28,2.03c0,1.13-1.05,1.61-1.98,1.61c-1.82,0-2.34-1.87-2.4-2.09L8.1,14.75 c0.63,2.19,2.28,2.78,3.02,2.96V19h1.75v-1.24c0.52-0.09,3.02-0.59,3.02-3.22C15.9,13.15,15.29,11.93,12.89,11.1z" />
                </g>
              </svg>
            ),
          },
          {
            name: "Transaction",
            href: "/admin/transaction",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#fff"
              >
                <path d="M0,0h24v24H0V0z" fill="none" />
                <g>
                  <path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M15,20H6c-0.55,0-1-0.45-1-1v-1h10V20z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" />
                  <rect height="2" width="6" x="9" y="7" />
                  <rect height="2" width="2" x="16" y="7" />
                  <rect height="2" width="6" x="9" y="10" />
                  <rect height="2" width="2" x="16" y="10" />
                </g>
              </svg>
            ),
          },
          {
            name: "Users",
            href: "/admin/users",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#fff"
              >
                <g>
                  <path d="M0,0h24v24H0V0z" fill="none" />
                </g>
                <g>
                  <g>
                    <path d="M4,18v-0.65c0-0.34,0.16-0.66,0.41-0.81C6.1,15.53,8.03,15,10,15c0.03,0,0.05,0,0.08,0.01c0.1-0.7,0.3-1.37,0.59-1.98 C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26c-0.42-0.6-0.75-1.28-0.97-2H4z" />
                    <path d="M10,12c2.21,0,4-1.79,4-4s-1.79-4-4-4C7.79,4,6,5.79,6,8S7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2s-0.9,2-2,2 c-1.1,0-2-0.9-2-2S8.9,6,10,6z" />
                    <path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" />
                  </g>
                </g>
              </svg>
            ),
          },
          {
            name: "Metrics",
            href: "/admin/metrics",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#fff"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
              </svg>
            ),
          },
          // {
          //   name: "Settings",
          //   href: "/settings",
          //   svg: (
          //     <svg
          //       xmlns="http://www.w3.org/2000/svg"
          //       height="24px"
          //       viewBox="0 0 24 24"
          //       width="24px"
          //       fill="#fff"
          //     >
          //       <path d="M0 0h24v24H0V0z" fill="none" />
          //       <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
          //     </svg>
          //   ),
          // },
        ]
      : menu(user.name);
    setMenus(menus);
  }, [user]);

  const currentPath =
    "/" +
    router.pathname
      .split("/")
      .filter((text: string, index: number) => index !== 0)
      .join("/");

  const checkActiveMenu = (item: any) => {
    if (
      currentPath.includes("[timeHome]") &&
      !currentPath.includes("[symbol]") &&
      item.href == "/daily"
    )
      return true;
    else if (currentPath.includes("[symbol]")) return false;
    else if (currentPath.includes("[user]") && item.href == `/u/${user.name}`)
      return true;
    else if (currentPath.includes(item.href)) return true;

    return false;
  };

  const handleClickMenu = (item: any) => {
    console.log("item.name", item);
    item.name !== "Markets" &&
      // item.name !== "Rewards" &&
      !isLogged &&
      setIsShowPopupSignUp(true);
  };

  return (
    <div className="sidebar">
      <div className="sidebar_menu">
        <div
          className="top-header_logo_img"
          onClick={() => {
            //   handleLeftMenuItem();
            // router.push(user?.admin ? "/daily" : "/daily");
            router.push(
              `/${String(
                optionHome == "METRICS" ? "daily" : optionHome
              ).toLowerCase()}`
            );
          }}
        >
          <img src="/LogoV3.svg" />
          <div className="top-header_logo_img_texts">
            <p className="title">StockBet</p>
            <p className="version">ALPHA</p>
          </div>
        </div>
        {
          // !isLoadingFetchUser &&
          menus.map((item, index) => (
            <li
              key={index}
              className={clsx(
                "sidebar_menu_item",
                checkActiveMenu(item) && "active"
              )}
            >
              {/* {String(currentPath)} */}
              <Link
                href={
                  item.name == "Markets"
                    ? `/${router.query.timeHome}` || item.href
                    : // : item.name == "Rewards"
                    // ? item.href
                    isLogged
                    ? item.href
                    : typeof window !== "undefined"
                    ? window.location.href
                    : "/"
                }
                key={item.name}
              >
                <div
                  className="itemLinkWrapper"
                  onClick={() => handleClickMenu(item)}
                >
                  <div className="group">
                    <div className="sidebar_menu_item_icon sidebar_menu_item_icon-normal">
                      {item.svg}
                    </div>
                    <div className="sidebar_menu_item_icon sidebar_menu_item_icon-collapsed">
                      {item.svgCollapsedActive || item.svg}
                    </div>
                    <p>{item.name}</p>
                  </div>
                </div>
              </Link>
            </li>
          ))
        }
      </div>

      <div
        className={clsx(
          "changeThemeWrapper",
          !isDarkTheme && "changeThemeWrapper-light"
        )}
      >
        <div className="btnTheme" onClick={handleChangeTheme}></div>
        <p>Dark Mode</p>
      </div>
    </div>
  );
}
