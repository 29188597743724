import React, { useEffect, useState } from "react";

import { useRouter } from "next/dist/client/router";
import { useAuth } from "../../context/AuthContext";

import Link from "next/link";
import clsx from "clsx";

export function menu(userName: any) {
  return [
    {
      name: "Markets",
      href: "/daily",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.121"
          height="19.5"
          viewBox="0 0 20.121 19.5"
        >
          <path
            id="Home"
            d="M3,12l2-2m0,0,7-7,7,7M5,10V20a1,1,0,0,0,1,1H9M19,10l2,2m-2-2V20a1,1,0,0,1-1,1H15M9,21a1,1,0,0,0,1-1V16a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v4a1,1,0,0,0,1,1M9,21h6"
            transform="translate(-1.939 -2.25)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            // opacity="0.6"
          />
        </svg>
      ),
      svgCollapsedActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
        >
          <path
            id="Home_Active"
            data-name="Home Active"
            d="M12.34,2.348a1.188,1.188,0,0,0-1.679,0L2.348,10.661A1.187,1.187,0,1,0,4.027,12.34l.348-.348v7.821A1.188,1.188,0,0,0,5.563,21H7.938a1.188,1.188,0,0,0,1.188-1.188V17.438a1.188,1.188,0,0,1,1.188-1.188h2.375a1.188,1.188,0,0,1,1.188,1.188v2.375A1.188,1.188,0,0,0,15.063,21h2.375a1.188,1.188,0,0,0,1.188-1.188V11.992l.348.348a1.187,1.187,0,1,0,1.679-1.679L12.34,2.348Z"
            transform="translate(-2 -2)"
            fill="#7f72da"
          />
        </svg>
      ),
    },
    {
      name: "Portfolio",
      href: `/u/${userName}`,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.5"
          height="19.5"
          viewBox="0 0 19.5 19.5"
        >
          <path
            id="Portfolio"
            d="M5.121,17.8a14.017,14.017,0,0,1,13.758,0M15,10a3,3,0,1,1-3-3,3,3,0,0,1,3,3Zm6,2a9,9,0,1,1-9-9,9,9,0,0,1,9,9Z"
            transform="translate(-2.25 -2.25)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            // opacity="0.6"
          />
        </svg>
      ),
      svgCollapsedActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.899"
          height="18.899"
          viewBox="0 0 18.899 18.899"
        >
          <path
            id="Portfolio_Active"
            data-name="Portfolio Active"
            d="M20.9,11.449A9.449,9.449,0,1,1,11.449,2,9.449,9.449,0,0,1,20.9,11.449ZM13.812,7.906a2.362,2.362,0,1,1-2.362-2.362,2.362,2.362,0,0,1,2.362,2.362Zm-2.362,4.725a5.906,5.906,0,0,0-5.37,3.445,7.089,7.089,0,0,0,10.74,0A5.906,5.906,0,0,0,11.449,12.631Z"
            transform="translate(-2 -2)"
            fill="#7f72da"
            fillRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   name: "Social",
    //   href: "/social",
    //   svg: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       xmlnsXlink="http://www.w3.org/1999/xlink"
    //       width="75"
    //       height="76"
    //       viewBox="0 0 75 76"
    //     >
    //       <defs>
    //         <clipPath id="clip-Social">
    //           <rect width="75" height="76" />
    //         </clipPath>
    //       </defs>
    //       <g id="Social" clipPath="url(#clip-Social)">
    //         <g
    //           id="Group_1058"
    //           data-name="Group 1058"
    //           transform="translate(0 0)"
    //         >
    //           <path
    //             id="Path_195"
    //             data-name="Path 195"
    //             d="M0,0H75.578V75.579H0Z"
    //             transform="translate(0)"
    //             fill="none"
    //           />
    //           <path
    //             id="Path_196"
    //             data-name="Path 196"
    //             d="M44.64,37.64l-9.447-9.447H13.149A3.149,3.149,0,0,1,10,25.044V6.149A3.149,3.149,0,0,1,13.149,3H41.491A3.149,3.149,0,0,1,44.64,6.149V37.64"
    //             transform="translate(21.491 6.447)"
    //             fill="none"
    //             stroke="#000"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="4"
    //           />
    //           <path
    //             id="Path_197"
    //             data-name="Path 197"
    //             d="M37.64,25.746v6.3a3.149,3.149,0,0,1-3.149,3.149H12.447L3,44.64V13.149A3.149,3.149,0,0,1,6.149,10h6.3"
    //             transform="translate(6.447 21.491)"
    //             fill="none"
    //             stroke="#000"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="4"
    //           />
    //         </g>
    //       </g>
    //     </svg>
    //   ),
    // },

    {
      name: "Funds",
      href: "/funds",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.5"
          height="15.5"
          viewBox="0 0 19.5 15.5"
        >
          <path
            id="Funds"
            d="M17,9V7a2,2,0,0,0-2-2H5A2,2,0,0,0,3,7v6a2,2,0,0,0,2,2H7m2,4H19a2,2,0,0,0,2-2V11a2,2,0,0,0-2-2H9a2,2,0,0,0-2,2v6A2,2,0,0,0,9,19Zm7-5a2,2,0,1,1-2-2A2,2,0,0,1,16,14Z"
            transform="translate(-2.25 -4.25)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            // opacity="0.6"
          />
        </svg>
      ),
      svgCollapsedActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.899"
          height="14.174"
          viewBox="0 0 18.899 14.174"
        >
          <path
            id="Funds_Active"
            data-name="Funds Active"
            d="M4.362,4A2.362,2.362,0,0,0,2,6.362v4.725A2.362,2.362,0,0,0,4.362,13.45V6.362H16.174A2.362,2.362,0,0,0,13.812,4Zm2.362,7.087A2.362,2.362,0,0,1,9.087,8.725h9.45A2.362,2.362,0,0,1,20.9,11.087v4.725a2.362,2.362,0,0,1-2.362,2.362H9.087a2.362,2.362,0,0,1-2.362-2.362Zm7.087,4.725A2.362,2.362,0,1,0,11.45,13.45,2.362,2.362,0,0,0,13.812,15.812Z"
            transform="translate(-2 -4)"
            fill="#7f72da"
            fillRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   name: "Following",
    //   href: "/following",
    //   svg: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="19.5"
    //       height="19.565"
    //       viewBox="0 0 19.5 19.565"
    //     >
    //       <path
    //         id="Following"
    //         d="M12,4.354a4,4,0,1,1,0,5.292M15,21H3V20a6,6,0,1,1,12,0Zm0,0h6V20a6,6,0,0,0-9-5.2M13,7A4,4,0,1,1,9,3a4,4,0,0,1,4,4Z"
    //         transform="translate(-2.25 -2.185)"
    //         fill="none"
    //         stroke="#fff"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="1.5"
    //         // opacity="0.6"
    //       />
    //     </svg>
    //   ),
    //   svgCollapsedActive: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="18.899"
    //       height="14.699"
    //       viewBox="0 0 18.899 14.699"
    //     >
    //       <path
    //         id="Following_Active"
    //         data-name="Following Active"
    //         d="M9.4,6.15A3.15,3.15,0,1,1,6.25,3,3.15,3.15,0,0,1,9.4,6.15Zm8.4,0A3.15,3.15,0,1,1,14.649,3,3.15,3.15,0,0,1,17.8,6.15ZM13.526,17.7a7.525,7.525,0,0,0,.073-1.05A7.318,7.318,0,0,0,12.024,12.1,5.25,5.25,0,0,1,19.9,16.649V17.7ZM6.25,11.4a5.25,5.25,0,0,1,5.25,5.25V17.7H1v-1.05A5.25,5.25,0,0,1,6.25,11.4Z"
    //         transform="translate(-1 -3)"
    //         fill="#7f72da"
    //       />
    //     </svg>
    //   ),
    // },

    {
      name: "Rewards",
      href: "/rewards",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.5"
          height="19.5"
          viewBox="0 0 19.5 19.5"
          className="mdl-js"
        >
          <path
            id="Path_459"
            data-name="Path 459"
            d="M12,8V21M12,8V6a2,2,0,1,1,2,2Zm0,0V5.5A2.5,2.5,0,1,0,9.5,8ZM5,12H19M5,12A2,2,0,0,1,5,8H19a2,2,0,0,1,0,4M5,12v7a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V12"
            transform="translate(-2.25 -2.25)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </svg>
      ),
      svgCollapsedActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16.002"
          viewBox="0 0 16 16.002"
          className="mdl-js"
        >
          <g
            id="Group_1864"
            data-name="Group 1864"
            transform="translate(-2 -1.998)"
          >
            <path
              id="Path_457"
              data-name="Path 457"
              d="M5,5a3,3,0,0,1,5-2.236A3,3,0,0,1,14.83,6H16a2,2,0,0,1,0,4H11V9A1,1,0,0,0,9,9v1H4A2,2,0,0,1,4,6H5.17A3.013,3.013,0,0,1,5,5ZM9,6V5A1,1,0,1,0,8,6Zm3,0a1,1,0,1,0-1-1V6Z"
              fill="#7f72da"
              fillRule="evenodd"
            />
            <path
              id="Path_458"
              data-name="Path 458"
              d="M9,11H3v5a2,2,0,0,0,2,2H9Zm2,7h4a2,2,0,0,0,2-2V11H11Z"
              fill="#7f72da"
            />
          </g>
        </svg>
      ),
    },

    // {
    //   name: "Chat",
    //   href: "/chat",
    //   svg: (
    //     <svg
    //       id="Group_291"
    //       data-name="Group 291"
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="24"
    //       height="25.239"
    //       viewBox="0 0 24 25.239"
    //     >
    //       <rect
    //         id="Rectangle_209"
    //         data-name="Rectangle 209"
    //         width="18"
    //         height="15.239"
    //         rx="2"
    //         transform="translate(3 5)"
    //         fill="none"
    //         stroke="#000000"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="1.5"
    //       />
    //       <path
    //         id="Path_107"
    //         data-name="Path 107"
    //         d="M3,7l9,6,9-6"
    //         transform="translate(0 0.482)"
    //         fill="none"
    //         stroke="#000000"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="1.5"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   name: "Settings",
    //   href: "/settings",
    //   svg: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       xmlnsXlink="http://www.w3.org/1999/xlink"
    //       width="75"
    //       height="76"
    //       viewBox="0 0 75 76"
    //     >
    //       <defs>
    //         <clipPath id="clip-Settings">
    //           <rect width="75" height="76" />
    //         </clipPath>
    //       </defs>
    //       <g id="Settings" clipPath="url(#clip-Settings)">
    //         <g id="Group_1056" data-name="Group 1056">
    //           <path
    //             id="Path_161"
    //             data-name="Path 161"
    //             d="M0,0H74.755V74.756H0Z"
    //             fill="none"
    //           />
    //           <path
    //             id="Path_162"
    //             data-name="Path 162"
    //             d="M25.816,7.1a5.369,5.369,0,0,1,10.435,0,5.37,5.37,0,0,0,8.014,3.32A5.372,5.372,0,0,1,51.647,17.8a5.37,5.37,0,0,0,3.317,8.011,5.369,5.369,0,0,1,0,10.435,5.37,5.37,0,0,0-3.32,8.014,5.372,5.372,0,0,1-7.382,7.382,5.37,5.37,0,0,0-8.011,3.317,5.369,5.369,0,0,1-10.435,0,5.37,5.37,0,0,0-8.014-3.32,5.372,5.372,0,0,1-7.382-7.382A5.37,5.37,0,0,0,7.1,36.251a5.369,5.369,0,0,1,0-10.435,5.37,5.37,0,0,0,3.32-8.014A5.372,5.372,0,0,1,17.8,10.42,5.365,5.365,0,0,0,25.816,7.1Z"
    //             transform="translate(6.344 6.345)"
    //             fill="none"
    //             stroke="#232325"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="4"
    //           />
    //           <ellipse
    //             id="Ellipse_62"
    //             data-name="Ellipse 62"
    //             cx="8.937"
    //             cy="8.938"
    //             rx="8.937"
    //             ry="8.938"
    //             transform="translate(29.047 27.834)"
    //             fill="none"
    //             stroke="#232325"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="4"
    //           />
    //         </g>
    //       </g>
    //     </svg>
    //   ),
    // },
  ];
}
export function menuMobileUser(userName: any) {
  return [
    {
      name: "Markets",
      href: "/daily",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.121"
          height="19.5"
          viewBox="0 0 20.121 19.5"
        >
          <path
            id="Home"
            d="M3,12l2-2m0,0,7-7,7,7M5,10V20a1,1,0,0,0,1,1H9M19,10l2,2m-2-2V20a1,1,0,0,1-1,1H15M9,21a1,1,0,0,0,1-1V16a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v4a1,1,0,0,0,1,1M9,21h6"
            transform="translate(-1.939 -2.25)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            opacity="0.6"
          />
        </svg>
      ),
      svgActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
        >
          <path
            id="Home_Active"
            data-name="Home Active"
            d="M12.34,2.348a1.188,1.188,0,0,0-1.679,0L2.348,10.661A1.187,1.187,0,1,0,4.027,12.34l.348-.348v7.821A1.188,1.188,0,0,0,5.563,21H7.938a1.188,1.188,0,0,0,1.188-1.188V17.438a1.188,1.188,0,0,1,1.188-1.188h2.375a1.188,1.188,0,0,1,1.188,1.188v2.375A1.188,1.188,0,0,0,15.063,21h2.375a1.188,1.188,0,0,0,1.188-1.188V11.992l.348.348a1.187,1.187,0,1,0,1.679-1.679L12.34,2.348Z"
            transform="translate(-2 -2)"
            fill="#7f72da"
          />
        </svg>
      ),
    },
    {
      name: "Portfolio",
      href: `/u/${userName}`,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.5"
          height="19.5"
          viewBox="0 0 19.5 19.5"
        >
          <path
            id="Portfolio"
            d="M5.121,17.8a14.017,14.017,0,0,1,13.758,0M15,10a3,3,0,1,1-3-3,3,3,0,0,1,3,3Zm6,2a9,9,0,1,1-9-9,9,9,0,0,1,9,9Z"
            transform="translate(-2.25 -2.25)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            opacity="0.6"
          />
        </svg>
      ),
      svgActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.899"
          height="18.899"
          viewBox="0 0 18.899 18.899"
        >
          <path
            id="Portfolio_Active"
            data-name="Portfolio Active"
            d="M20.9,11.449A9.449,9.449,0,1,1,11.449,2,9.449,9.449,0,0,1,20.9,11.449ZM13.812,7.906a2.362,2.362,0,1,1-2.362-2.362,2.362,2.362,0,0,1,2.362,2.362Zm-2.362,4.725a5.906,5.906,0,0,0-5.37,3.445,7.089,7.089,0,0,0,10.74,0A5.906,5.906,0,0,0,11.449,12.631Z"
            transform="translate(-2 -2)"
            fill="#7f72da"
            fillRule="evenodd"
          />
        </svg>
      ),
    },

    {
      name: "Funds",
      href: "/funds",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.5"
          height="15.5"
          viewBox="0 0 19.5 15.5"
        >
          <path
            id="Funds"
            d="M17,9V7a2,2,0,0,0-2-2H5A2,2,0,0,0,3,7v6a2,2,0,0,0,2,2H7m2,4H19a2,2,0,0,0,2-2V11a2,2,0,0,0-2-2H9a2,2,0,0,0-2,2v6A2,2,0,0,0,9,19Zm7-5a2,2,0,1,1-2-2A2,2,0,0,1,16,14Z"
            transform="translate(-2.25 -4.25)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            opacity="0.6"
          />
        </svg>
      ),
      svgActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.899"
          height="14.174"
          viewBox="0 0 18.899 14.174"
        >
          <path
            id="Funds_Active"
            data-name="Funds Active"
            d="M4.362,4A2.362,2.362,0,0,0,2,6.362v4.725A2.362,2.362,0,0,0,4.362,13.45V6.362H16.174A2.362,2.362,0,0,0,13.812,4Zm2.362,7.087A2.362,2.362,0,0,1,9.087,8.725h9.45A2.362,2.362,0,0,1,20.9,11.087v4.725a2.362,2.362,0,0,1-2.362,2.362H9.087a2.362,2.362,0,0,1-2.362-2.362Zm7.087,4.725A2.362,2.362,0,1,0,11.45,13.45,2.362,2.362,0,0,0,13.812,15.812Z"
            transform="translate(-2 -4)"
            fill="#7f72da"
            fillRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   name: "Following",
    //   href: "/following",
    //   svg: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="19.5"
    //       height="19.565"
    //       viewBox="0 0 19.5 19.565"
    //     >
    //       <path
    //         id="Following"
    //         d="M12,4.354a4,4,0,1,1,0,5.292M15,21H3V20a6,6,0,1,1,12,0Zm0,0h6V20a6,6,0,0,0-9-5.2M13,7A4,4,0,1,1,9,3a4,4,0,0,1,4,4Z"
    //         transform="translate(-2.25 -2.185)"
    //         fill="none"
    //         stroke="#fff"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="1.5"
    //         opacity="0.6"
    //       />
    //     </svg>
    //   ),
    //   svgActive: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="18.899"
    //       height="14.699"
    //       viewBox="0 0 18.899 14.699"
    //     >
    //       <path
    //         id="Following_Active"
    //         data-name="Following Active"
    //         d="M9.4,6.15A3.15,3.15,0,1,1,6.25,3,3.15,3.15,0,0,1,9.4,6.15Zm8.4,0A3.15,3.15,0,1,1,14.649,3,3.15,3.15,0,0,1,17.8,6.15ZM13.526,17.7a7.525,7.525,0,0,0,.073-1.05A7.318,7.318,0,0,0,12.024,12.1,5.25,5.25,0,0,1,19.9,16.649V17.7ZM6.25,11.4a5.25,5.25,0,0,1,5.25,5.25V17.7H1v-1.05A5.25,5.25,0,0,1,6.25,11.4Z"
    //         transform="translate(-1 -3)"
    //         fill="#7f72da"
    //       />
    //     </svg>
    //   ),
    // },
    {
      name: "Trades",
      href: "/trades",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.665"
          height="22.141"
          viewBox="0 0 17.665 22.141"
        >
          <path
            id="Path_392"
            data-name="Path 392"
            d="M9.476,18.665V16.427m3.357,2.238V14.189m3.357,4.476V11.951m2.238,11.189H7.238A2.238,2.238,0,0,1,5,20.9V5.238A2.238,2.238,0,0,1,7.238,3h6.25a1.119,1.119,0,0,1,.791.328l6.058,6.058a1.119,1.119,0,0,1,.328.791V20.9A2.238,2.238,0,0,1,18.427,23.141Z"
            transform="translate(-4 -2)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            opacity="0.4"
          />
        </svg>
      ),
      svgActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.105"
          height="20.141"
          viewBox="0 0 15.105 20.141"
        >
          <path
            id="Path_393"
            data-name="Path 393"
            d="M6.518,2A2.518,2.518,0,0,0,4,4.518V19.623a2.518,2.518,0,0,0,2.518,2.518h10.07a2.518,2.518,0,0,0,2.518-2.518V8.815a2.517,2.517,0,0,0-.737-1.78l-4.3-4.3A2.518,2.518,0,0,0,12.29,2ZM9.035,14.588a1.259,1.259,0,0,0-2.518,0v3.776a1.259,1.259,0,0,0,2.518,0Zm2.518-3.776a1.259,1.259,0,0,1,1.259,1.259v6.294a1.259,1.259,0,0,1-2.518,0V12.07A1.259,1.259,0,0,1,11.553,10.812Zm5.035-1.259a1.259,1.259,0,1,0-2.518,0v8.812a1.259,1.259,0,1,0,2.518,0Z"
            transform="translate(-4 -2)"
            fill="#7f72da"
            fillRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Rewards",
      href: "/rewards",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.5"
          height="19.5"
          viewBox="0 0 19.5 19.5"
          className="mdl-js"
        >
          <path
            id="Path_459"
            data-name="Path 459"
            d="M12,8V21M12,8V6a2,2,0,1,1,2,2Zm0,0V5.5A2.5,2.5,0,1,0,9.5,8ZM5,12H19M5,12A2,2,0,0,1,5,8H19a2,2,0,0,1,0,4M5,12v7a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V12"
            transform="translate(-2.25 -2.25)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            opacity="0.6"
          />
        </svg>
      ),
      svgActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16.002"
          viewBox="0 0 16 16.002"
          className="mdl-js"
        >
          <g
            id="Group_1864"
            data-name="Group 1864"
            transform="translate(-2 -1.998)"
          >
            <path
              id="Path_457"
              data-name="Path 457"
              d="M5,5a3,3,0,0,1,5-2.236A3,3,0,0,1,14.83,6H16a2,2,0,0,1,0,4H11V9A1,1,0,0,0,9,9v1H4A2,2,0,0,1,4,6H5.17A3.013,3.013,0,0,1,5,5ZM9,6V5A1,1,0,1,0,8,6Zm3,0a1,1,0,1,0-1-1V6Z"
              fill="#7f72da"
              fillRule="evenodd"
            />
            <path
              id="Path_458"
              data-name="Path 458"
              d="M9,11H3v5a2,2,0,0,0,2,2H9Zm2,7h4a2,2,0,0,0,2-2V11H11Z"
              fill="#7f72da"
            />
          </g>
        </svg>
      ),
    },
    {
      name: "Notifications",
      href: "/notifications",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="20"
          viewBox="0 0 18 20"
          className="mdl-js"
        >
          <path
            id="Path_456"
            data-name="Path 456"
            d="M15,17h5l-1.405-1.4A2.032,2.032,0,0,1,18,14.158V11a6,6,0,0,0-4-5.659V5a2,2,0,1,0-4,0v.341A6,6,0,0,0,6,11v3.159A2.032,2.032,0,0,1,5.4,15.6L4,17H9m6,0v1a3,3,0,0,1-6,0V17m6,0H9"
            transform="translate(-3 -2)"
            fill="none"
            stroke="#7b7d7f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      ),
      svgActive: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="16"
          viewBox="0 0 14 16"
          className="mdl-js"
        >
          <path
            id="Path_457"
            data-name="Path 457"
            d="M10,2A6,6,0,0,0,4,8v3.586l-.707.707A1,1,0,0,0,4,14H16a1,1,0,0,0,.707-1.707L16,11.586V8A6,6,0,0,0,10,2Zm0,16a3,3,0,0,1-3-3h6A3,3,0,0,1,10,18Z"
            transform="translate(-3 -2)"
            fill="#7f72da"
          />
        </svg>
      ),
    },
    // {
    //   name: "Chat",
    //   href: "/chat",
    //   svg: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="20.5"
    //       height="20.5"
    //       viewBox="0 0 20.5 20.5"
    //     >
    //       <path
    //         id="Chat"
    //         d="M17.389,8.111h2.056A2.056,2.056,0,0,1,21.5,10.167v6.167a2.056,2.056,0,0,1-2.056,2.056H17.389V22.5l-4.111-4.111H9.167a2.049,2.049,0,0,1-1.453-.6m0,0,3.509-3.509h4.111a2.056,2.056,0,0,0,2.056-2.056V6.056A2.056,2.056,0,0,0,15.333,4H5.056A2.056,2.056,0,0,0,3,6.056v6.167a2.056,2.056,0,0,0,2.056,2.056H7.111v4.111Z"
    //         transform="translate(-2 -3)"
    //         fill="none"
    //         stroke="#fff"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="2"
    //         opacity="0.6"
    //       />
    //     </svg>
    //   ),
    //   svgActive: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="20.267"
    //       height="19"
    //       viewBox="0 0 20.267 19"
    //     >
    //       <g
    //         id="Chat_Active"
    //         data-name="Chat Active"
    //         transform="translate(-2 -3)"
    //       >
    //         <path
    //           id="Path_120"
    //           data-name="Path 120"
    //           d="M2,5.533A2.533,2.533,0,0,1,4.533,3H13.4a2.533,2.533,0,0,1,2.533,2.533V10.6A2.533,2.533,0,0,1,13.4,13.133H10.867l-3.8,3.8v-3.8H4.533A2.533,2.533,0,0,1,2,10.6Z"
    //           fill="#7f72da"
    //         />
    //         <path
    //           id="Path_121"
    //           data-name="Path 121"
    //           d="M16.85,7V9.533A5.067,5.067,0,0,1,11.784,14.6H10.3L8.062,16.838a2.52,2.52,0,0,0,1.188.3h2.533l3.8,3.8v-3.8h2.533A2.533,2.533,0,0,0,20.65,14.6V9.533A2.533,2.533,0,0,0,18.117,7Z"
    //           transform="translate(1.617 1.067)"
    //           fill="#7f72da"
    //         />
    //       </g>
    //     </svg>
    //   ),
    // },
    // {
    //   name: "Settings",
    //   href: "/settings",
    //   svg: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       xmlnsXlink="http://www.w3.org/1999/xlink"
    //       width="75"
    //       height="76"
    //       viewBox="0 0 75 76"
    //     >
    //       <defs>
    //         <clipPath id="clip-Settings">
    //           <rect width="75" height="76" />
    //         </clipPath>
    //       </defs>
    //       <g id="Settings" clipPath="url(#clip-Settings)">
    //         <g id="Group_1056" data-name="Group 1056">
    //           <path
    //             id="Path_161"
    //             data-name="Path 161"
    //             d="M0,0H74.755V74.756H0Z"
    //             fill="none"
    //           />
    //           <path
    //             id="Path_162"
    //             data-name="Path 162"
    //             d="M25.816,7.1a5.369,5.369,0,0,1,10.435,0,5.37,5.37,0,0,0,8.014,3.32A5.372,5.372,0,0,1,51.647,17.8a5.37,5.37,0,0,0,3.317,8.011,5.369,5.369,0,0,1,0,10.435,5.37,5.37,0,0,0-3.32,8.014,5.372,5.372,0,0,1-7.382,7.382,5.37,5.37,0,0,0-8.011,3.317,5.369,5.369,0,0,1-10.435,0,5.37,5.37,0,0,0-8.014-3.32,5.372,5.372,0,0,1-7.382-7.382A5.37,5.37,0,0,0,7.1,36.251a5.369,5.369,0,0,1,0-10.435,5.37,5.37,0,0,0,3.32-8.014A5.372,5.372,0,0,1,17.8,10.42,5.365,5.365,0,0,0,25.816,7.1Z"
    //             transform="translate(6.344 6.345)"
    //             fill="none"
    //             stroke="#232325"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="4"
    //           />
    //           <ellipse
    //             id="Ellipse_62"
    //             data-name="Ellipse 62"
    //             cx="8.937"
    //             cy="8.938"
    //             rx="8.937"
    //             ry="8.938"
    //             transform="translate(29.047 27.834)"
    //             fill="none"
    //             stroke="#232325"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="4"
    //           />
    //         </g>
    //       </g>
    //     </svg>
    //   ),
    // },
  ];
}
export default function FooterMobile() {
  const {
    user,
    search,
    setSearch,
    isLoadingFetchUser,
    isLogged,
    useIsShowPopup,
  } = useAuth();

  const [
    isShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupLogin,
    setIsShowPopupSignUp,
  ] = useIsShowPopup;

  const handleClickMenu = (item: any) => {
    if (item.name !== "Markets" && item.name !== "Trades" && !isLogged) {
      setIsShowPopupSignUp(true);
    } else {
      router.push(
        item.name == "Markets"
          ? item.href
          : isLogged
          ? item.href
          : typeof window !== "undefined"
          ? window.location.href
          : "/"
      );
    }
  };

  const router = useRouter();
  const [menus, setMenus] = useState<any>([]);
  const currentPath =
    "/" +
    router.pathname
      .split("/")
      .filter((text: string, index: number) => index !== 0)
      .join("/");

  const checkActiveMenu = (item: any) => {
    if (
      currentPath.includes("[timeHome]") &&
      !currentPath.includes("[symbol]") &&
      item.href == "/daily"
    )
      return true;
    else if (currentPath.includes("[symbol]")) return false;
    else if (currentPath.includes("[user]") && item.href == `/u/${user.name}`)
      return true;
    else if (currentPath.includes(item.href)) return true;

    return false;
  };
  useEffect(() => {
    const menus = user.admin
      ? [
          {
            name: "/Markets",
            href: "/daily",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="29"
                height="29"
                viewBox="0 0 29 29"
              >
                <defs>
                  <clipPath id="clipPath">
                    <rect
                      id="Rectangle_16"
                      data-name="Rectangle 16"
                      width="29"
                      height="29"
                      transform="translate(-0.315)"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Home"
                  transform="translate(0.315)"
                  clipPath="url(#clipPath)"
                >
                  <g
                    id="Group_1060"
                    data-name="Group 1060"
                    transform="translate(-0.204 0.311)"
                  >
                    <path
                      id="Path_152"
                      data-name="Path 152"
                      d="M5.352,13.584H3L13.584,3,24.168,13.584H21.816"
                      transform="translate(0.528 0.528)"
                      fill="none"
                      stroke="#72747b"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_153"
                      data-name="Path 153"
                      d="M5,12v8.231a2.352,2.352,0,0,0,2.352,2.352h11.76a2.352,2.352,0,0,0,2.352-2.352V12"
                      transform="translate(0.88 2.112)"
                      fill="none"
                      stroke="#72747b"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_154"
                      data-name="Path 154"
                      d="M9,22.408V15.352A2.352,2.352,0,0,1,11.352,13H13.7a2.352,2.352,0,0,1,2.352,2.352v7.056"
                      transform="translate(1.584 2.288)"
                      fill="none"
                      stroke="#72747b"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </g>
              </svg>
            ),
          },
          {
            name: "Portfolio",
            href: `/u/${user.name}`,
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="29"
                height="32"
                viewBox="0 0 29 32"
              >
                <defs>
                  <clipPath id="clipPath">
                    <rect
                      id="Rectangle_18"
                      data-name="Rectangle 18"
                      width="29"
                      height="32"
                      transform="translate(-0.315 -0.033)"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Portfolio"
                  transform="translate(0.315 0.033)"
                  clipPath="url(#clipPath)"
                >
                  <g
                    id="Group_1059"
                    data-name="Group 1059"
                    transform="translate(0.229 1.147)"
                  >
                    <path
                      id="Path_159"
                      data-name="Path 159"
                      d="M20.016,8.928v-3.7A1.232,1.232,0,0,0,18.784,4H6.464a2.464,2.464,0,1,0,0,4.928H21.248A1.232,1.232,0,0,1,22.48,10.16v3.7m0,4.928v3.7a1.232,1.232,0,0,1-1.232,1.232H6.464A2.464,2.464,0,0,1,4,21.248V6.464"
                      transform="translate(0.928 0.928)"
                      fill="none"
                      stroke="#72747b"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_160"
                      data-name="Path 160"
                      d="M21.392,12v4.928H16.464a2.464,2.464,0,1,1,0-4.928h4.928"
                      transform="translate(3.248 2.785)"
                      fill="none"
                      stroke="#72747b"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </g>
              </svg>
            ),
          },
          {
            name: "Withdraw",
            href: "/admin/withdraw",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <g>
                  <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8c0-4.41,3.59-8,8-8 s8,3.59,8,8C20,16.41,16.41,20,12,20z M12.89,11.1c-1.78-0.59-2.64-0.96-2.64-1.9c0-1.02,1.11-1.39,1.81-1.39 c1.31,0,1.79,0.99,1.9,1.34l1.58-0.67c-0.15-0.44-0.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6,0.56-2.62,2.85-2.62,2.96 c0,2.27,2.25,2.91,3.35,3.31c1.58,0.56,2.28,1.07,2.28,2.03c0,1.13-1.05,1.61-1.98,1.61c-1.82,0-2.34-1.87-2.4-2.09L8.1,14.75 c0.63,2.19,2.28,2.78,3.02,2.96V19h1.75v-1.24c0.52-0.09,3.02-0.59,3.02-3.22C15.9,13.15,15.29,11.93,12.89,11.1z" />
                </g>
              </svg>
            ),
          },
          {
            name: "Transaction",
            href: "/admin/transaction",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <g>
                  <path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M15,20H6c-0.55,0-1-0.45-1-1v-1h10V20z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" />
                  <rect height="2" width="6" x="9" y="7" />
                  <rect height="2" width="2" x="16" y="7" />
                  <rect height="2" width="6" x="9" y="10" />
                  <rect height="2" width="2" x="16" y="10" />
                </g>
              </svg>
            ),
          },
          {
            name: "Users",
            href: "/admin/users",
            svg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
              </svg>
            ),
          },
        ]
      : menuMobileUser(user.name);
    setMenus(menus);
  }, [user]);

  return (
    <div className={clsx("footer-mobile", user.admin && "footer-mobile-admin")}>
      {!isLoadingFetchUser &&
        menus.map((item, index) => (
          <li
            key={index}
            className={clsx(
              "footer-mobile_item",
              checkActiveMenu(item) && "active"
            )}
            onClick={() => handleClickMenu(item)}
          >
            <Link
              href={
                item.name == "Markets"
                  ? item.href
                  : isLogged
                  ? item.href
                  : typeof window !== "undefined"
                  ? window.location.href
                  : "/"
              }
              key={item.name}
            >
              {checkActiveMenu(item) ? item?.svgActive || item.svg : item.svg}
            </Link>
          </li>
        ))}
    </div>
  );
}
