import clsx from "clsx";
import Image from "next/image";
import React from "react";
import Button from "../common/Button";

import UpIcon from "../../assets/img/svg/arrow-up.svg";
import DownIcon from "../../assets/img/svg/arrow-down.svg";
import { useRouter } from "next/router";
import searchIcon from "../../assets/img/search_icon.svg";
import { useAuth } from "../../context/AuthContext";

type TypeOptionsHome = "DAILY" | "WEEKLY" | "MONTHLY";

const HeadLeft = ({}) => {
  const {
    user,
    setUser,
    search,
    setSearch,
    useIsShowPopup,
    useOptionHome,
    isLoadingFetchUser,
  } = useAuth();

  const [optionHome, setOptionHome] = useOptionHome;
  const router = useRouter();
  const { symbol } = router.query;

  const handleChangeSearch = (e) => {
    setSearch(e.target.value.trim());
    if (
      router.pathname !== "/metrics" &&
      router.pathname !== "/stocks" &&
      e.target.value.trim() !== ""
    ) {
      !user.admin && router.push("/stocks");
    }
  };

  const handleClickOptionHome = (opt: TypeOptionsHome) => {
    setOptionHome(opt);
  };

  const handleClickDaily = () => {
    if (router.pathname.includes("/[timeHome]/[symbol]")) {
      router.push(`/daily/${symbol}`);
    } else if (router.pathname.includes("/[timeHome]")) {
      router.push(`/daily`);
    }
  };
  const handleClickWeekly = () => {
    if (router.pathname.includes("/[timeHome]/[symbol]")) {
      router.push(`/weekly/${symbol}`);
    } else if (router.pathname.includes("/[timeHome]")) {
      router.push(`/weekly`);
    }
  };
  const handleClickMonthly = () => {
    if (router.pathname.includes("/[timeHome]/[symbol]")) {
      router.push(`/monthly/${symbol}`);
    } else if (router.pathname.includes("/[timeHome]")) {
      router.push(`/monthly`);
    }
  };

  return (
    <>
      <div className="top-header_left">
        <div
          className={clsx(
            "top-header_search",
            !(
              router.pathname.includes("/home") ||
              router.pathname.includes("/daily") ||
              router.pathname.includes("/weekly") ||
              router.pathname.includes("/monthly") ||
              router.pathname.includes("/[timeHome]") ||
              router.pathname.includes("/metrics/[symbol]") ||
              router.pathname.includes("/admin/stocks") ||
              router.pathname.includes("/rewards")
            ) && "top-header_search-full"
          )}
        >
          <div className="top-header_search_icon">
            <Image src={searchIcon} alt="" width={24} height={24} />
          </div>
          <input
            value={search}
            placeholder="Search markets"
            onChange={handleChangeSearch}
          />
        </div>

        <div
          className={clsx(
            "top-header_options",
            !(
              router.pathname.includes("/home") ||
              router.pathname.includes("/daily") ||
              router.pathname.includes("/weekly") ||
              router.pathname.includes("/monthly") ||
              router.pathname.includes("/[timeHome]") ||
              router.pathname.includes("/metrics/[symbol]") ||
              router.pathname.includes("/admin/stocks") ||
              router.pathname.includes("/rewards")
            ) && "top-header_options-hide"
          )}
        >
          <div
            className={clsx(
              "optionsWrapperHome",
              `optionsWrapperHome-${optionHome}`
            )}
          >
            <Button
              className="btnOptHome"
              text="Daily"
              onClick={
                router.pathname.includes("/rewards")
                  ? () => {}
                  : () => {
                      handleClickOptionHome("DAILY");
                      handleClickDaily();
                    }
              }
              active={optionHome == "DAILY"}
            />
            <Button
              className="btnOptHome"
              text="Weekly"
              onClick={() => {
                handleClickOptionHome("WEEKLY");
                handleClickWeekly();
              }}
              active={optionHome == "WEEKLY"}
            />
            <Button
              className="btnOptHome"
              text="Monthly"
              onClick={
                router.pathname.includes("/rewards")
                  ? () => {}
                  : () => {
                      handleClickOptionHome("MONTHLY");
                      handleClickMonthly();
                    }
              }
              active={optionHome == "MONTHLY"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeadLeft;
