import { useAuth } from "../../context/AuthContext";
import Button from "../common/Button";
import { IDataShare } from "../../context/AuthContext";
import moment from "moment";
import { capFirst, percentOfBet, textTimeOptionOfBet } from "../../utils/local";
import { useEffect, useState } from "react";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

import { saveAs } from "file-saver";
import twitterSvg from "../../assets/img/svg/twitter.svg";
import imagePurpleSvg from "../../assets/img/svg/image-purple.svg";
import getFeatherClient from "../../utils/featherClient";
import { useRouter } from "next/router";
import clsx from "clsx";
import { TypeTimeOptionsActive } from "../symbol/BuySellBox";

const ShareImage = () => {
  const { useDataShare } = useAuth();
  const { dataShare, setDataShare } = useDataShare;

  const [isGenerateImage, setIsGenerateImage] = useState<boolean>(false);
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);

  const uploadService = getFeatherClient().service("uploads");

  const covertPrice = (data: IDataShare) => {
    return data.result == "Win"
      ? `+$${Number(data.price)?.toFixed(2)}`
      : `-$${Number(data.price)?.toFixed(2)}`;
  };

  const covertTitle = (data: IDataShare) => {
    // {item.timeOption.includes("HIGHER") ? ` +${percentOfBet({ timeOption: item?.timeOption})}%` : item.timeOption.includes("LOWER") ? ` -${percentOfBet({ timeOption: item?.timeOption})}%` : ""}

    console.log({ data });
    return `${data.symbol} ${data.status}${
      data.timeOption.includes("HIGHER")
        ? ` +${percentOfBet({
            timeOption: data?.timeOption as TypeTimeOptionsActive,
          })}%`
        : data.timeOption.includes("LOWER")
        ? ` -${percentOfBet({
            timeOption: data?.timeOption as TypeTimeOptionsActive,
          })}%`
        : ""
    } ${
      data.timeOption.includes("MONTHLY")
        ? `${moment(data.date).format("MMMM")} (M)`
        : `${moment(data.date).format("M/D")} ${textTimeOptionOfBet({
            timeOptionExtend: data.timeOption as TypeTimeOptionsActive,
          })}`
    }`;
  };

  const handleCloseViewer = () => {
    setDataShare({ ...dataShare, isDisplay: false });

    const screenElm = document.querySelector("#screenId") as HTMLElement;
    screenElm && (screenElm.style.filter = ``);
  };

  const handleDownloadImageShare = () => {
    const imgShareElm = document.querySelector(
      ".shareImage_img"
    ) as HTMLElement;

    console.log({ imgShareElm });
    if (imgShareElm && dataShare) {
      setIsGenerateImage(true);
      htmlToImage
        .toJpeg(imgShareElm, {
          quality: 1 || 0.95,
        })
        .then(function (dataUrl) {
          setIsGenerateImage(false);
          var link = document.createElement("a");
          link.download = `${covertTitle(dataShare)}.jpeg`;
          link.href = dataUrl;
          console.log({ dataUrl });
          link.click();
        })
        .catch((err) => {
          console.log({ err });
          setIsGenerateImage(false);
        });
    }
  };

  const handleShareOnTwitter = () => {
    const imgShareMetaElm = document.querySelector(
      ".shareImage_imgMeta"
    ) as HTMLElement;
    console.log({ imgShareMetaElm });
    imgShareMetaElm && dataShare && setIsGenerateImage(true);
    imgShareMetaElm.style.display = "block";
    htmlToImage
      .toJpeg(imgShareMetaElm, {
        quality: 1 || 0.95,
      })
      .then(function (dataUrl) {
        imgShareMetaElm.style.display = "";
        setIsGenerateImage(false);
        // setImageBase64(dataUrl);
        setIsUploadingImage(true);
        uploadService
          .create({ uri: dataUrl })
          .then((response: any) => {
            setIsUploadingImage(false);
            console.log("Server responded with: ", response);
            console.log("to page:", window.location.href);

            const href = String(window.location.href)
              .split("")
              .map((char: string) => {
                //https://support.cpanel.net/hc/en-us/articles/360057100494-How-to-display-special-characters-in-a-URL-
                // space = %20 = % + 2 + 0 = %25 + 2 + 5
                return char == "%" ? "%25" : char;
              })
              .join("");

            console.log({ href });

            window.open(
              `http://twitter.com/share?url=${href}/${response.id}&text=This%20is%20my%20result%0a`,
              "_blank"
            );
          })
          .catch((err) => {
            imgShareMetaElm.style.display = "";
            setIsUploadingImage(false);
          });
      })
      .catch((err) => {
        setIsGenerateImage(false);
      });
  };

  const handleClickImg = () => {
    const btnCloseImageElm = document.querySelector(
      ".shareImage .btnCloseImage"
    ) as HTMLElement;

    btnCloseImageElm && (btnCloseImageElm.style.display = "block");
  };

  return (
    <>
      <div className="shareImageWrapper">
        <div className="shareImageWrapper_bg" onClick={handleCloseViewer}></div>
        {/* <div className="shareImage">
          <div className="shareImage_imgMeta">
            <img src={"/images/Horizontal_Image_empty.png"} alt="" />
            <div className="shareImage_imgMeta_content">
              <p className="title">{dataShare && covertTitle(dataShare)}</p>
              <p className="text">{"Closed P&L"}</p>
              <p className="price">
                {dataShare?.price && covertPrice(dataShare)}
              </p>
            </div>
          </div>
        </div> */}

        <div className="shareImage">
          <div className="shareImage_imgMeta">
            <img src={"/images/Horizontal_Image_empty.png"} alt="" />
            <div className="shareImage_imgMeta_content">
              <p className="title">{dataShare && covertTitle(dataShare)}</p>
              <p className="text">{"Closed P&L"}</p>
              <p className="price">
                {dataShare?.price && covertPrice(dataShare)}
              </p>
            </div>
          </div>

          <div className="shareImage_img" onClick={handleClickImg}>
            <Button
              className="btnCloseImage"
              text="x"
              onClick={() => handleCloseViewer()}
            />
            <img src={"/images/Share_Image.png"} alt="" />
            <div className="shareImage_img_content">
              <p className="title">{dataShare && covertTitle(dataShare)}</p>
              <p className="text">{"Closed P&L"}</p>
              <p className="price">
                {dataShare?.price && covertPrice(dataShare)}
              </p>
            </div>
          </div>

          <div className="shareImage_buttons">
            <Button
              className={clsx(
                "btnShare",
                isGenerateImage && "disable",
                isUploadingImage && "disable"
              )}
              onClick={
                isGenerateImage || isUploadingImage
                  ? () => {}
                  : () => handleShareOnTwitter()
              }
            >
              <>
                <p>
                  <span>Share on</span>{" "}
                  <span>
                    <img src={twitterSvg.src} alt="" />
                  </span>
                </p>
              </>
            </Button>
            <Button
              className={clsx("btnSaveImage", isGenerateImage && "disable")}
              onClick={
                isGenerateImage || isUploadingImage
                  ? () => {}
                  : () => handleDownloadImageShare()
              }
            >
              <>
                <p>
                  <span>Save Image</span>{" "}
                  <span>
                    <img src={imagePurpleSvg.src} alt="" />
                  </span>
                </p>
              </>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareImage;

interface IOptionShareImage {
  urlImage: string;
}
interface ShareImageAfterUploadProps {
  options?: IOptionShareImage;
}

export const ShareImageAfterUpload = ({
  options,
}: ShareImageAfterUploadProps) => {
  const router = useRouter();
  return (
    <>
      <div className="shareImageWrapper">
        <div className="shareImageWrapper_bg   shareImageWrapper_bg-url"></div>
        <div className="shareImage shareImage-url">
          <div className="shareImage_img">
            <img
              src={options?.urlImage}
              alt=""
              onClick={() => {
                // console.log(window.location.href.split("/").slice(0,-1).join("/"));
                const userUrl = window.location.href
                  .split("/")
                  .slice(0, -1)
                  .join("/");
                router.push(userUrl);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
