import moment from "moment";
import { capFirst } from "../../utils/local";

export interface INotificationItem {
  amount: string | number;
  type: string | "BUY_WIN" | "BUY_LOSE";
  transaction: string;
  createdAt: string;
  newSubscriber?: {
    _id: string;
    name: string;
  };
  isRead: boolean;
}

interface NotificationItemProps {
  item: INotificationItem;
  handleClick?: any;
}

const NotificationItem = ({ item, handleClick }: NotificationItemProps) => {
  const imgTrade =
    item?.type == "BUY_WIN"
      ? "/icons/win_notification.svg"
      : item?.type == "BUY_LOSE"
      ? "/icons/lose_notification.svg"
      : item?.type == "FILLED_ORDER_BUY_SHARES" ||
        item?.type == "FILLED_ORDER_SELL_SHARES"
      ? "/icons/limit_full_notification.svg"
      : item?.type == "NEW_SUBSCRIBER"
      ? "/icons/user_sub_notification.svg"
      : item?.type == "HIGHEST_PREDICTION_RATE" ||
        item?.type == "MOST_WINNING_TRADES" ||
        item?.type == "MOST_PROFITABLE_SINGLE_TRADE" ||
        item?.type == "WORST_TRADER_EVER_AWARD" ||
        item?.type == "SYMBOL_PREDICTION_RATE"
      ? "/icons/weekly_reward_notification.svg"
      : ""; // weekly_reward_notification.svg  //  user_sub_notification.svg
  const titleLeft =
    item?.type == "BUY_WIN"
      ? "Winning Trade"
      : item?.type == "BUY_LOSE"
      ? "Losing Trade"
      : item?.type == "FILLED_ORDER_BUY_SHARES" ||
        item?.type == "FILLED_ORDER_SELL_SHARES"
      ? "Limit Order Filled"
      : item?.type == "NEW_SUBSCRIBER"
      ? "New Subscriber"
      : // : item?.type == "HIGHEST_PREDICTION_RATE"
      // ? "Reward Highest Prediction Rate"
      // : item?.type == "MOST_WINNING_TRADES"
      // ? "Reward Most Winning Trades"
      // : item?.type == "MOST_PROFITABLE_SINGLE_TRADE"
      // ? "Reward Most Profitable Single trade"
      // : item?.type == "WORST_TRADER_EVER_AWARD"
      // ? "Reward Worst Trader Ever award"
      // : item?.type == "SYMBOL_PREDICTION_RATE"
      // ? "SPY Prediction Rate"
      item?.type == "HIGHEST_PREDICTION_RATE" ||
        item?.type == "MOST_WINNING_TRADES" ||
        item?.type == "MOST_PROFITABLE_SINGLE_TRADE" ||
        item?.type == "WORST_TRADER_EVER_AWARD" ||
        item?.type == "SYMBOL_PREDICTION_RATE"
      ? "Weekly Rewards"
      : "Some thing."; //
  const number = `${
    item?.type == "BUY_WIN" ||
    item?.type == "NEW_SUBSCRIBER" ||
    item?.type == "FILLED_ORDER_SELL_SHARES" ||
    item?.type == "HIGHEST_PREDICTION_RATE" ||
    item?.type == "MOST_WINNING_TRADES" ||
    item?.type == "MOST_PROFITABLE_SINGLE_TRADE" ||
    item?.type == "WORST_TRADER_EVER_AWARD" ||
    item?.type == "SYMBOL_PREDICTION_RATE"
      ? "+"
      : "-"
  }$${Number(item?.amount)?.toFixed(2)}`;

  //

  const textLeft = () => {
    const text = item?.transaction.split(" ");

    if (item?.type == "BUY_WIN" || item?.type == "BUY_LOSE") {
      return `${text[0]} ${text[3]} ${text[1]} ${moment(text[text.length - 1]).format(
        "M/D"
      )}`; // ex: Green AAPL Daily → AAPL Green Daily
    } else if (
      item?.type == "FILLED_ORDER_BUY_SHARES" ||
      item?.type == "FILLED_ORDER_SELL_SHARES"
    ) {
      return (
        `${text[1]} ${text[4]} ${text[3]} ${text[2]} ${moment(text[text.length - 1]).format(
          "M/D"
        )}` || item.transaction
      ); // ex: `Buy Green AMC Daily`
    } else if (item?.type == "NEW_SUBSCRIBER") {
      return item?.newSubscriber?.name;
    } else if (
      item?.type == "HIGHEST_PREDICTION_RATE" ||
      item?.type == "MOST_WINNING_TRADES" ||
      item?.type == "MOST_PROFITABLE_SINGLE_TRADE" ||
      item?.type == "WORST_TRADER_EVER_AWARD" ||
      item?.type == "SYMBOL_PREDICTION_RATE"
    ) {
      return item?.type
        ?.split("_")
        .map((char: string) => (char == "SYMBOL" ? "SPY" : capFirst(char)))
        .join(" ");
      // return `Weekly Rewards` || `Reward ${text[4]}`; //
    }

    return item?.type || `Some thing.`;
  };
  return (
    <>
      <div className="notificationItem" onClick={() => handleClick(item)}>
        <div className="notificationItem_left">
          <img src={imgTrade} alt="" />
        </div>
        <div className="notificationItem_right">
          <p className="title">
            <span className="left">{titleLeft}</span>
            <span className="right">{number}</span>
          </p>
          <p className="text">
            <span className="left">{textLeft()}</span>
            <span className="right">Share</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default NotificationItem;
