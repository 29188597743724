import React, { useEffect, useState } from "react";

import { useRouter } from "next/dist/client/router";
// import logo from "../../assets/img/logoV2.png";
import clsx from "clsx";

import { useAuth } from "../../context/AuthContext";
import { userGetInfoAPI } from "../../utils/restClient";
import { IUserInfo } from "../../models";

import HeadLeft from "./HeadLeft";
import HeadRight from "./HeadRight";
import getFeatherClient from "../../utils/featherClient";

export default function TopHeader() {
  const {
    user,
    setUser,
    search,
    setSearch,
    useIsShowPopup,
    isLoadingFetchUser,
  } = useAuth();
  const [
    isShowPopupLogin,
    setIsShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupSignUp,
  ] = useIsShowPopup;
  const router = useRouter();
  // const userInfoService = getFeatherClient().service("user-get-info");

  // const [isDarkTheme, setIsDarkTheme] = useState<boolean>(true);

  // const handleChangeTheme = () => {
  //   const rootElm = document.documentElement as HTMLElement;
  //   if (rootElm) {
  //     rootElm.dataset.theme = !isDarkTheme ? "" : "light";
  //     localStorage.setItem("theme", !isDarkTheme ? "" : "light");
  //   }
  //   setIsDarkTheme(!isDarkTheme);
  //   // changeTheme at Bet detail
  //   const btnChangeThemeChartElm = document.querySelector(
  //     "#btnChangeThemeChartId"
  //   ) as HTMLElement;
  //   btnChangeThemeChartElm && btnChangeThemeChartElm.click();
  // };

  useEffect(() => {
    // const checkAuth = localStorage.getItem("auth");
    // const checkTheme = localStorage.getItem("theme");
    // if (checkTheme == "light" && checkAuth) {
    //   const rootElm = document.documentElement as HTMLElement;
    //   rootElm.dataset.theme = "light";
    //   setIsDarkTheme(false);
    // }
  }, []);

  useEffect(() => {
    const userEventService = getFeatherClient().service(
      "user-realtime-events"
    );
    if (user._id) {
      userEventService.on("update-balance-user", (data: any) => {
        console.log({ data, user });
        setUser({
          ...user,
          balance: data.newBalance,
        });
      });
    }

    return () => {
      userEventService.removeListener("update-balance-user");
    };
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(user).length > 0 && user._id && !isLoadingFetchUser) {
      userGetInfoAPI
        .find()
        .then((resUserInfo: IUserInfo) => {
          console.log({ resUserInfo });
          setUser({
            ...user,
            balance: resUserInfo.balance,
            investments: resUserInfo.investments,
            portfolioValue: resUserInfo.portfolioValue,
          });
        })
        .catch((error: Error) => {
          // authenticate error
          console.log(error);
        });
    }
  }, [isLoadingFetchUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const classHide =
    router.pathname.includes("/home/") ||
    router.pathname.includes("/[timeHome]/[symbol]") ||
    router.pathname.includes("/metrics/[symbol]")
      ? // router.pathname.includes("/funds") ||
        // router.pathname.includes("/following")
        "topHeaderWrapper-hideDesktop"
      : router.pathname.includes("/admin/transaction")
      ? " topHeaderWrapper-transactionPage"
      : " ";
  return (
    <div className={clsx("topHeaderWrapper", classHide)}>
      <div className="top-header">
        <HeadLeft />
        <HeadRight />
      </div>
    </div>
  );
}
