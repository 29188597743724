import feathers, { rest } from "@feathersjs/client";
import axios from "axios";
import yetch from "yetch";

// const storage = require('localstorage-memory');

export const serverUrl = process.env.NEXT_PUBLIC_SERVER_URL || "http://localhost:4000";
const app = feathers();
const restClient = rest(serverUrl);
app.configure(
  restClient.axios(
    axios.create({
      headers: {},
    })
  )
);

app.hooks({
  before: {
    all: [
      (hook) => {
        const apiKey = window.localStorage["auth"];
        if (apiKey) {
          hook.params.headers = { ["Authorization"]: `Bearer ${apiKey}` };
        }
      },
    ],
  },
});

export const userRest = app.service("users");
export const stockRestAPI = app.service("stocks");
export const detailStockRestAPI = app.service("bet-all");
export const calendarRestAPI = app.service("calendar");
export const getTopMovesAPI = app.service("get-top-moves");
export const betRestAPI = app.service("bet");
export const randomNumberBetsRestAPI = app.service("random-number-bets");
export const getUserRestAPI = app.service("get-user");
export const getStockNewRestAPI = app.service("get-stock-news");
export const getTopBigMoversAPI = app.service("big-movers");
export const loginMetricsRestApi = app.service("login-metrics");
export const dataMetricsRestApi = app.service("data-metrics");
export const recentTradesApi = app.service("recent-trades");
export const followApi = app.service("user/follow");

export const chartStockAPI = app.service("chart-stock");
export const myPositionsAPI = app.service("my-positions");
export const getStockAPI = app.service("get-stock");
export const userGetInfoAPI = app.service("user-get-info");
export const userPositionAPI = app.service("user/position");
export const messagesAPI = app.service("messages");
export const voteStocksAPI = app.service("vote-stocks");

export const orderSymbolAPI = app.service("order-symbol");

export const getChartsMetricsAPI = app.service("charts-metrics");
export const metricsSymbolAPI = app.service("metrics-symbol");

export const checkPromotionNewUserAPI = app.service("check-promotion-new-user");

//
export const csvDataAPI = app.service("csv-data");
export const notificationsAPI = app.service("notifications");
export const readNotificationsAPI = app.service("read-notifications");
// rewards
export const rewardsAPI = app.service("rewards");


