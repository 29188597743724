import React from "react";
import { useRouter } from "next/dist/client/router";

import footerLogoIcon from "../../assets/img/svg/footerLogo.svg";
import footerLogoLightIcon from "../../assets/img/svg/footerLogoLight.svg";
import { useAuth } from "../../context/AuthContext";

export default function Footer() {
  const router = useRouter();

  const { useOptionHome } = useAuth();

  const [timeOption] = useOptionHome;
  return (
    <div className="footerWrapper">
      <div className="footerLeft">
        <div
          className="logoWrapper"
          onClick={() => {
            router.push(`/${String(timeOption).toLowerCase()}`);
          }}
        >
          <div className="logoLeft">
            <img src="/LogoV3.svg" />
          </div>
          <div className="logoRight">
            <div className="title">StockBet</div>
            <div className="version">ALPHA</div>
          </div>
        </div>
        <div className="listPage">
          <div className="line">
            <a href="mailto:support@stockbet.io">Contact</a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://stockbet.notion.site/Privacy-Policy-7ab7c7fa804d495aa0ee32538eda33e8"
            >
              Privacy Policy
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://stockbet.notion.site/Terms-of-Use-3e0c26048ffe4009adb87412dcf2f36c"
            >
              Terms of Use
            </a>
          </div>
          <div className="line">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://stockbet.notion.site/How-It-Works-5cd9759a88204f3cb588e26e5d757d34"
            >
              How it Works
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://stockbet.notion.site/FAQs-825fea8793ff4531af711a72074bde08"
            >
              FAQs
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://stockbetapp.medium.com/"
            >
              Blog
            </a>
          </div>
        </div>
        <div className="poweredBy">
          <p>POWERED BY</p>
          <img className="footerLogoDark" src={footerLogoIcon.src} alt="" />
          <img
            className="footerLogoLight"
            src={footerLogoLightIcon.src}
            alt=""
          />
          <p>STOCKBET</p>
        </div>
        <div className="credit">StockBet Labs Inc. © 2022</div>
      </div>
      <div className="footerRight">
        <div className="title">Disclaimer</div>
        <div className="content">
          The StockBet website (“Site”) is for informational and educational
          purposes only. The Site is currently still in alpha. All new alpha
          users agree to not hold the Site liable for any bugs or potential
          losses that may occur. None of the material on the Site is intended to
          be, or does it constitute, a solicitation, recommendation or offer to
          buy or sell any security, commodity interest, derivative, financial
          product or instrument. Users are responsible for complying with al
          applicable laws and should conduct their own analysis and consult with
          professional advisors prior to entering into any contracts. Trading is
          not available to Restricted Persons. See our Terms of Use.
        </div>
      </div>
    </div>
  );
}
