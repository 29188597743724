import auth from "@feathersjs/authentication-client";
import feathers from "@feathersjs/client";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";

let _client;

const serverUrl = process.env.NEXT_PUBLIC_SERVER_URL || "http://localhost:4000";

const getFeatherClient = () => {
  if (_client) {
    return _client;
  }
  const socket = io(serverUrl);
  _client = feathers();
  _client.configure(
    socketio(socket, {
      timeout: 7000,
    })
  );
  // _client.configure(
  //   feathers.authentication({
  //     storage: window.localStorage,
  //   })
  // );
  _client.configure(auth({ storageKey: "auth" }));

  return _client;
};

export default getFeatherClient;
