import clsx from "clsx";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";

import UpIcon from "../../assets/img/svg/arrow-up.svg";
import DownIcon from "../../assets/img/svg/arrow-down.svg";
import { useRouter } from "next/router";
import searchIcon from "../../assets/img/search_icon.svg";
import { useAuth } from "../../context/AuthContext";
import CountUp from "react-countup";

import { trackGoal } from "fathom-client";
import { SIGN_UP_CODE, LOGIN_CODE } from "../../utils/fathomCode";
import { notificationsAPI, readNotificationsAPI } from "../../utils/restClient";
import NotificationItem, { INotificationItem } from "./NotificationItem";
import getFeatherClient from "../../utils/featherClient";
import { getSymbolFromTransaction } from "../../utils/stock";
import { getTimeOptionFromTrade } from "../../utils/local";

interface INotification {
  title: string;
}

const HeadRight = () => {
  const {
    user,
    setUser,
    search,
    setSearch,
    useIsShowPopup,
    isLoadingFetchUser,
    useOptionHome,
    useDataShare,
    useNotifications,
  } = useAuth();
  const [
    isShowPopupLogin,
    setIsShowPopupLogin,
    isShowPopupSignUp,
    setIsShowPopupSignUp,
  ] = useIsShowPopup;

  const { dataShare, setDataShare } = useDataShare;

  const [timeOption] = useOptionHome;

  const [optionTotal, setOptionTotal] = useState([
    {
      title: "Funds",
      keyValue: "balance",
    },
    {
      title: "Account" || "Portfolio", // Also change Portfolio to Account (top right)
      keyValue: "portfolioValue",
    },
    {
      title: "Investment",
      keyValue: "investments",
    },
  ]);

  const {
    isShowNotifications,
    setIsShowNotifications,
    isReadNotifications,
    setIsReadNotifications,
  } = useNotifications;

  const [notifications, setNotifications] = useState<INotificationItem[]>([]);
  // const [isShowNotifications, setIsShowNotifications] =
  //   useState<boolean>(false);
  // const [isReadNotifications, setIsReadNotifications] = useState<boolean>(true);

  const router = useRouter();

  const handleOnClickUp = () => {
    setOptionTotal(([first, ...rest]) => [...rest, first]);
  };

  const handleClickBell = () => {
    setIsShowNotifications(!isShowNotifications);
    // if (!isShowNotifications) {
    setRead();
    // }
    // setIsReadNotifications(!isReadNotifications);
  };

  const fetchNotifications = () => {
    notificationsAPI.find().then((resNotifications: any) => {
      console.log({ resNotifications });
      setNotifications(resNotifications.data.recentTransactions);
      // check read
      if (
        resNotifications.data.recentTransactions.some(
          (trade: INotificationItem) => trade.isRead == false
        )
      ) {
        setIsReadNotifications(false);
      } else {
        setIsReadNotifications(true);
      }
    });
  };

  const setRead = () => {
    readNotificationsAPI.find().then((resReadNotifications: any) => {
      console.log({ resReadNotifications });
      setIsReadNotifications(true);
    });
  };

  const handleCreateImageShare = (item: INotificationItem) => {
    console.log({ transaction: item });

    if (item.type == "BUY_WIN" || item.type == "BUY_LOSE") {
      const date = item.transaction.split(" ")?.[item.transaction.split(" ").length - 1]; // "SPY Monthly Loss Red 2022-06-30"
      setDataShare({
        ...dataShare,
        isDisplay: true,
        date: date,
        price: item.amount,
        symbol: item.transaction && getSymbolFromTransaction(item.transaction),
        timeOption: getTimeOptionFromTrade({
          transactionText: item.transaction,
        }),

        result: item.transaction?.toLocaleUpperCase().includes("WIN")
          ? "Win"
          : "Loss",

        status: item.transaction?.toLocaleUpperCase().includes("GREEN")
          ? "Green"
          : item.transaction?.toLocaleUpperCase().includes("RED")
          ? "Red"
          : item.transaction?.toLocaleUpperCase().includes("YES")
          ? "Yes"
          : item.transaction?.toLocaleUpperCase().includes("NO")
          ? "No"
          : "",
      });

      const screenElm = document.querySelector("#screenId") as HTMLElement;
      screenElm && (screenElm.style.filter = `blur(7px)`);
    }
  };

  useEffect(() => {
    console.log("-realTime notifications-");
    const stocksService = getFeatherClient().service("user-realtime-events");
    stocksService.on("notifications", (data: any) => {
      console.log("notifications realtime", data);
      console.log({ isShowNotifications });
      if (!isShowNotifications) {
        setIsReadNotifications(false);
      } else {
        // setIsReadNotifications(true);
        setRead();
      }
      if (data.newTrade) {
        setNotifications((prevNotifications) => {
          console.log([data.newTrade, ...prevNotifications]);
          return [data.newTrade, ...prevNotifications];
        });
      }
    });

    return () => {
      console.log("- remove realtime notifications -");
      stocksService.removeListener("notifications");
    };
  }, []);

  useEffect(() => {
    if (user._id && notifications.length == 0) {
      fetchNotifications();
    }
  }, [user._id]);

  return (
    <>
      <div className="top-header_right">
        {/* logo in mobile */}
        <div
          className="headerLogo"
          onClick={() => {
            router.push(`/${String(timeOption).toLowerCase()}`);
          }}
        >
          <img src="/LogoV3.svg" />
          <p>StockBet</p>
        </div>

        {!user._id && (
          <div className="top-header_search_button">
            <div className="top-header_auth">
              <div
                className="top-header_auth_sign-in"
                onClick={() => {
                  setIsShowPopupLogin(true);
                  setIsShowPopupSignUp(false);
                  trackGoal(LOGIN_CODE, 0);
                }}
              >
                <p>Log In</p>
              </div>
              <div
                className="top-header_auth_sign-up"
                onClick={() => {
                  setIsShowPopupLogin(false);
                  setIsShowPopupSignUp(true);
                }}
              >
                <p>Early Access</p>
              </div>
              {/* <div
                className="top-header_auth_sign-up"
                onClick={() => {
                  setIsShowPopupLogin(false);
                  setIsShowPopupSignUp(true);
                  trackGoal(SIGN_UP_CODE, 0);
                }}
              >
                <p>Sign Up</p>
              </div> */}
            </div>
          </div>
        )}
        {user._id && (
          <>
            <div className="valuesUser" onClick={handleOnClickUp}>
              {optionTotal.map((item, index) => (
                <div className="valuesUser_item" key={index}>
                  <p>{item.title}</p>
                  <div className="right">
                    <p>
                      $
                      {(user[item.keyValue] === 0 ? (
                        "0.00"
                      ) : (
                        <CountUp
                          decimal={"."}
                          decimals={2}
                          end={Number(user[item.keyValue])}
                          duration={0.3}
                          separator=","
                        />
                      )) || "0"}
                    </p>
                    <div className="icon">
                      <Image src={UpIcon} />
                      <Image src={DownIcon} />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="bell" onClick={handleClickBell}>
              {isReadNotifications ? (
                <img src="/icons/bell.svg" alt="" />
              ) : (
                <img src="/icons/bell_unread.svg" alt="" />
              )}
            </div>

            {isShowNotifications && (
              <div className="notificationsWrapper">
                <p className="titleBig">Notifications</p>
                <div className="notifications">
                  {notifications.map((nofiItem: any, index: number) => (
                    <NotificationItem
                      key={index}
                      item={nofiItem}
                      handleClick={handleCreateImageShare}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HeadRight;
