import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect } from "react";
import { SnackbarProvider } from "notistack";
import { useRouter } from "next/router";
import MainLayout from "../layouts/main";
import * as Fathom from "fathom-client";

// import "../assets/scss/index.scss";
// import "../styles/globals.css";
import "../assets/scss/index.scss";

import { AuthProvider } from "../context/AuthContext";
import * as gtag from "../lib/gtag";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { pathname } = router;
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // Initialize Fathom when the app loads
    // Example: yourdomain.com
    //  - Do not include https://
    //  - This must be an exact match of your domain.
    //  - If you're using www. for your domain, make sure you include that here.

    Fathom.load(process.env.NEXT_PUBLIC_YOUR_FATHOM_TRACKING_CODE || "", {
      includedDomains: [
        process.env.NEXT_PUBLIC_YOUR_FATHOM_SITE || "stockbet.io",
      ],
    });

    function onRouteChangeComplete(url) {
      // console.log({ url });
      Fathom.trackPageview({ url });
    }
    // Record a pageview when route changes
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        hideIconVariant
      >
        <AuthProvider>
          <Head>
            <title>StockBet | Trade Directly on Market Outcomes</title>
            <meta
              name="description"
              content="StockBet makes hedging simple and straightforward by letting retail investors place directional bets on stock price. Just choose Green or Red."
            />
            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            {/* <meta property="og:url" content="https://stockbet.io/" /> */}
            <meta
              property="og:title"
              content="StockBet | Trade Directly on Market Outcomes"
            />
            <meta
              property="og:description"
              content="StockBet is a binary options exchange where retail investors can trade directly on market outcomes (alpha v.0.4.1)."
            />
            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            {/* <meta property="twitter:url" content="https://stockbet.io/" /> */}
            <meta
              property="twitter:title"
              content="StockBet | Trade Directly on Market Outcomes"
            />
            <meta
              property="twitter:description"
              content="StockBet is a binary options exchange where retail investors can trade directly on market outcomes (alpha v.0.4.1)."
            />

            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="google" content="notranslate"></meta>
            {/* Chrome, Firefox OS and Opera  */}
            <meta name="theme-color" content="#23272a"></meta>
            {/* Windows Phone  */}
            <meta name="msapplication-navbutton-color" content="#23272a"></meta>
            {/* iOS Safari  */}
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="#23272a"
            ></meta>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />

            <meta
              property="og:description"
              content="StockBet is a binary options exchange where retail investors can trade directly on market outcomes (alpha v.0.4.1)."
            />
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
            />
            <script
              async
              dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
              }}
            />
          </Head>
          <MainLayout>
            <Component {...pageProps} />
          </MainLayout>
        </AuthProvider>
      </SnackbarProvider>
    </>
  );
}
export default MyApp;
